@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  button {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    width: 230px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
}
