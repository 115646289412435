@import 'utilities';

//  ========================================================================
//  Variables
//  ======================================================================== 
$enable-grid-classes          : true !default;

$grid-breakpoints             : ( xs: 0, xm: 420px, sm: 576px, md: 768px, lg: 991px, xl: 1200px, xxl: 1500px) !default;
$container-max-widths         : ( sm: 540px, md: 720px, lg: 960px, xl: 1140px ) !default;

$grid-columns                 : 12 !default;
$grid-gutter-width            : 32px !default;
$grid-row-columns             : 6 !default;

//  ========================================================================
//  Colors
//  ========================================================================
$white                        : #fff !default;
$black                        : #000 !default;
$black-30                     : #00000050 !default;
$grey                         : #3047850D !default;
$blue                         : #6A82C5 !default;
$grey-secondary               : #F5F5F5 !default;

$red-light-1                  : #EB94A0 !default;
$red-light-2                  : #F6D3D7 !default;

$primary                      : #304785 !default;
$primary-70                   : rgba(48, 71, 133, 0.7) !default;
$primary-darker               : #1D2B52 !default;
$primary-dark                 : #141D38 !default;
$secondary                    : #FF5560 !default;
$text-secondary               : #595454 !default;
$text-tertiary                : #768491 !default;
$text-tertiary-pale           : #00000080 !default;

$background                   : #FAFAFA !default;
$background-green             : #55C167 !default;
$background-secondary         : #E1E5EB !default;
$background-tertiary          : #F2F2F2 !default;
$background-light-green       : #55c1660d !default;
$background-light-secondary   : #ff55600d !default;

$border-grey                  : #D5D5D5 !default;
$border-grey-secondary        : #DDDDDD !default;
$box-shadow                   : #00000029 !default;
$border-grey-secondary        : rgba(0, 0 , 44, 0.1) !default;

$loader-background-color      : rgba(255, 255, 255, 0.1);

//  ========================================================================
//  Fonts
//  ========================================================================
$font-main                    : 'Inter', 'Arial', sans-serif;

$font-secondary               : normal normal normal 18px/28px Inter; //Inter, Regular
//  font-weights:             : 200 300 400 500 700
$font-fibon-bold              : $font-fibon-neue-bold, 'Arial', sans-serif;
$font-fibon-medium            : $font-fibon-neue-medium, 'Arial', sans-serif;
$font-fibon-regular           : $font-fibon-neue-regular, 'Arial', sans-serif;

// Italic
$font-fibon-neue-regular_oblique: 'fibonneue-oblique';
$font-fibon-neue-bold_oblique: 'fibonneue-boldoblique';
$font-fibon-neue-medium_oblique: 'fibonneue-mediumoblique';

$globalMaxWidth: 1134px;
