@import '../variables';
@import '../mixins';

.form {
  &-label {
    color: $white;
    width: 100%;
    margin-bottom: 8px;
  }

  &-error {
    @include font($size: 12px, $color: $white);
    margin: -10px 0 20px 0;
  }

  input {
    border: 1px solid $white;
    background: transparent;
    width: 100%;
    margin-bottom: 20px;
    color: $white;
  }
}

.submit-button {
  color: $secondary;
  width: 100%;
}
