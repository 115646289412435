@import '../mixins';

.wrapper {
    cursor: pointer;
    margin-bottom: 60px;

    &:hover{
        opacity: 0.8;
    }

    @include media-breakpoint-down(xs) {
        text-align: center;
    }
}

.see-more-button{
    margin-top: 20px;
    color: $secondary;
    text-decoration: underline;
    cursor: pointer;
}

.image-wrapper {
    display: flex;
    align-items: center;
    width: 90%;
    height: 60px;
    overflow: hidden;
    margin-bottom: 40px;

    @include media-breakpoint-down(xs) {
        width: 100%;
        justify-content: center;
    }
}

.image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}