@import 'mixins';

.general-small-padding {
  // padding: 0 240px; //
  @include media-breakpoint-down(xl) {
    padding: 0 200px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 160px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 120px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 60px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 20px;
  }
}

.general-small-padding-left {
  // padding-left: 240px; //
  @include media-breakpoint-down(xl) {
    padding-left: 200px;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 160px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 100px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 40px;
  }

  @include media-breakpoint-down(xm) {
    padding-left: 20px;
  }
}

.general-padding {
  // padding: 0 150px; //
  @include media-breakpoint-down(xl) {
    padding: 0 120px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 100px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 80px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 40px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 20px;
  }
}

.section-top-bottom-padding {
  padding: 150px 0;

  @include media-breakpoint-down(xl) {
    padding: 120px 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 100px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 80px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 0;
  }
}

.section-no-padding {
  padding: 0;

  @include media-breakpoint-down(xl) {
    padding: 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.p-font-size {
  p {
    font-size: 20px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }
}
