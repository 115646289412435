@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.wrapper {
  h3 {
    @include displayFlex($display: flex, $justify-content: center);
    padding: 30px 0;
    margin-top: 20px;
    border-bottom: 1px solid $border-grey;
  }
}

.content-wrapper {
  margin: 50px 0;
  color: $black;
  white-space: pre-wrap;

  p, span, div {
    strong {
      font-weight: 700;
    }
  }

  ol, ul {
    padding-left: 30px;
  }
}

.cookie-button {
  margin-top: 50px;
}
