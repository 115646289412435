@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);
  margin-top: 35px;

  p {
    @include font($size: 14px, $transform: uppercase, $color: $text-tertiary);
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.courses {
  &-wrapper {
    display: flex;

    & > div {
      flex-grow: 1;
    }
  }

  &-select {
    background: $grey-secondary;
    border: none !important;
    padding: 0 18px !important;
    word-break: break-word;

    span {
      @include font($size: 20px, $color: $primary);
    }
  }

  &-button {
    @include displayFlex($display: flex, $justify-content: center, $wrap: wrap, $align-items: center);
    @include font($size: 16px, $family: $font-main);
    margin-left: 80px;

    &--icon {
      margin-left: 16px;
      @include displayFlex($display: flex, $wrap: wrap);
      @include size($width: 18px, $height: 18px);

      span {
        @include displayFlex($display: flex, $wrap: wrap, $justify-content: space-between);
        @include size($width: 18px, $height: 4px);


        &:not(:last-child) {
          margin-bottom: 3px;
        }

        span {
          @include size($width: 4px, $height: 4px);
          background: $white;

          &:not(:last-child) {
            margin-right: 3px;
          }
        }
      }
    }
  }
}
