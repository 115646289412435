$font-path: '../../fonts/fibon-neue/';

$font-fibon-neue-bold: 'fibonneue-bold';
$font-fibon-neue-regular: 'fibonneue-regular';
$font-fibon-neue-medium: 'fibonneue-medium';

// Italic
$font-fibon-neue-regular_oblique: 'fibonneue-oblique';
$font-fibon-neue-bold_oblique: 'fibonneue-boldoblique';
$font-fibon-neue-medium_oblique: 'fibonneue-mediumoblique';

@font-face {
  font-family: '#{$font-fibon-neue-bold}';
  src: url('#{$font-path}#{$font-fibon-neue-bold}.eot');
  src: url('#{$font-path}#{$font-fibon-neue-bold}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}#{$font-fibon-neue-bold}.otf') format('opentype'),
  url('#{$font-path}#{$font-fibon-neue-bold}.woff') format('woff'),
  url('#{$font-path}#{$font-fibon-neue-bold}.woff2') format('woff2'),
  url('#{$font-path}#{$font-fibon-neue-bold}.ttf') format('truetype'),
  url('#{$font-path}#{$font-fibon-neue-bold}.svg#fibon_neuebold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '#{$font-fibon-neue-medium}';
  src: url('#{$font-path}#{$font-fibon-neue-medium}.eot');
  src: url('#{$font-path}#{$font-fibon-neue-medium}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}#{$font-fibon-neue-medium}.otf') format('opentype'),
  url('#{$font-path}#{$font-fibon-neue-medium}.woff') format('woff'),
  url('#{$font-path}#{$font-fibon-neue-medium}.woff2') format('woff2'),
  url('#{$font-path}#{$font-fibon-neue-medium}.ttf') format('truetype'),
  url('#{$font-path}#{$font-fibon-neue-medium}.svg#fibon_neuemedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '#{$font-fibon-neue-regular}';
  src: url('#{$font-path}#{$font-fibon-neue-regular}.eot');
  src: url('#{$font-path}#{$font-fibon-neue-regular}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}#{$font-fibon-neue-regular}.otf') format('opentype'),
  url('#{$font-path}#{$font-fibon-neue-regular}.woff') format('woff'),
  url('#{$font-path}#{$font-fibon-neue-regular}.woff2') format('woff2'),
  url('#{$font-path}#{$font-fibon-neue-regular}.ttf') format('truetype'),
  url('#{$font-path}#{$font-fibon-neue-regular}.svg#fibon_neueregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Italic
@font-face {
  font-family: '#{$font-fibon-neue-regular_oblique}';
  src: url('#{$font-path}#{$font-fibon-neue-regular_oblique}.eot');
  src: url('#{$font-path}#{$font-fibon-neue-regular_oblique}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}#{$font-fibon-neue-regular_oblique}.otf') format('opentype'),
  url('#{$font-path}#{$font-fibon-neue-regular_oblique}.woff') format('woff'),
  url('#{$font-path}#{$font-fibon-neue-regular_oblique}.woff2') format('woff2'),
  url('#{$font-path}#{$font-fibon-neue-regular_oblique}.ttf') format('truetype'),
  url('#{$font-path}#{$font-fibon-neue-regular_oblique}.svg#fibon_neueregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '#{$font-fibon-neue-bold_oblique}';
  src: url('#{$font-path}#{$font-fibon-neue-bold_oblique}.eot');
  src: url('#{$font-path}#{$font-fibon-neue-bold_oblique}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}#{$font-fibon-neue-bold_oblique}.otf') format('opentype'),
  url('#{$font-path}#{$font-fibon-neue-bold_oblique}.woff') format('woff'),
  url('#{$font-path}#{$font-fibon-neue-bold_oblique}.woff2') format('woff2'),
  url('#{$font-path}#{$font-fibon-neue-bold_oblique}.ttf') format('truetype'),
  url('#{$font-path}#{$font-fibon-neue-bold_oblique}.svg#fibon_neueregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '#{$font-fibon-neue-medium_oblique}';
  src: url('#{$font-path}#{$font-fibon-neue-medium_oblique}.eot');
  src: url('#{$font-path}#{$font-fibon-neue-medium_oblique}.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}#{$font-fibon-neue-medium_oblique}.otf') format('opentype'),
  url('#{$font-path}#{$font-fibon-neue-medium_oblique}.woff') format('woff'),
  url('#{$font-path}#{$font-fibon-neue-medium_oblique}.woff2') format('woff2'),
  url('#{$font-path}#{$font-fibon-neue-medium_oblique}.ttf') format('truetype'),
  url('#{$font-path}#{$font-fibon-neue-medium_oblique}.svg#fibon_neueregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
