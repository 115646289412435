@import '../variables';
@import '../mixins';

.wrapper {
  overflow: hidden;

  @include displayFlex($display: flex, $direction: column);
  margin-bottom: 20px;

  @include media-breakpoint-up(sm) {
    margin-top: 5px;
  }

  hr {
    width: 100%;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    padding: 0;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &:last-child {
      display: none;
    }
  }

  & > div {
    @include displayFlex($display: flex, $direction: row, $align-items: center);

    figure {
      @include image-aspect(9, 6, 50%);
      cursor: pointer;
      max-height: 80px;

      img {
        border-radius: 3px;
      }
    }

    span {
      @include font($size: 14px, $family: $font-fibon-bold, $color: $black);
      width: 50%;
      padding: 20px;
      line-height: 14px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}

.course-wrapper {
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    padding: 0 60px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 20px;
  }
}
