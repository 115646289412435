@import '../mixins';

.title {
  margin: 30px 0;

  @include media-breakpoint-down(sm) {
    margin: 24px 0;
  }
}

.subtitle {
  margin: 31px 0;
  font-size: 24px;
}

.margin-between {
  margin: 12px 0;
}

.submit-button {
  @include displayFlex($display: flex, $justify-content: flex-end);
}

.submit-button-destroy {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include displayFlex($display: flex, $justify-content: flex-end);
  margin-top: 2rem;
}

.warning-password {
  padding-top: 12px;
  font-size: 14px;
}

.input-title {
  font-weight: 400;
}
