@import '../variables';
@import '../mixins';

.wrapper {
  color: $black;
  margin: 40px 0;
  white-space: pre-wrap;

  p, span, div {
    strong {
      font-weight: 700;
    }
  }

  a {
    @include clickEffect();
    color: $primary;
    text-decoration: underline !important;
  }
}

.course {
  &-title {
    color: $primary;
    padding: 22px 0 20px;
  }

  &-wrapper {
    & > div {
      padding-bottom: 30px;

      &:not(:first-child) {
        padding-top: 30px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $border-grey-secondary;
      }
    }
  }
}

.plan {
  &-module {
    @include font($size: 16px, $color: $text-tertiary-pale);
  }

  &-title {
    @include font($size: 20px, $family: $font-fibon-bold, $color: $primary);
    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
    padding: 5px 0;
  }

  &-text {
    font-size: 20px;
  }
}

.courseDescription {
  color: $primary;
}