@import '../mixins';
@import '../variables';

.container {
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }

  max-width: 70%;

  img {
    @include size($width: 100%, $height: auto);

    border-radius: 10px;
  }
}

.button-container {
  @include displayFlex($display: flex, $justify-content: flex-end);

  button {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  margin: 40px 0 80px 0;
}

.back-button {
  @include displayFlex($display: flex, $align-items: center);

  @include media-breakpoint-down(sm) {
    margin: 30px 0px 20px;
  }

  color: $secondary;
  font-size: 16px;
  cursor: pointer;
  margin: 60px 0px 30px;

  p {
    font-size: 16px;
    margin-left: 13px;
  }
}

.small {
  font-size: 16px;
}


