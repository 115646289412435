@import '../variables';
@import '../mixins';

.wrapper {
  position: relative;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.menu {
  * {
    background-color: white;
  }

  nav {
    @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
  }

  &-login {
    @include position($position: fixed, $top: 0, $right: 0);
  }

  &-links {
    @include font($size: 24px, $transform: uppercase, $weight: 700);
    @include clearOutline();

    @include media-breakpoint-down(sm) {
      @include font($size: 24px, $transform: uppercase, $weight: 700, $family: $font-fibon-regular);
    }

    & > span {
      cursor: pointer;
    }

    &--login {
      @include font($size: 16px, $weight: 700);
      @include media-breakpoint-down(sm) {
        @include font($size: 16px, $weight: 700, $family: $font-fibon-regular);
      }
      @include clearOutline();

      span {
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }

  &-button {
    margin-left: 20px;

    &--login {
      @include position($position: absolute, $top: -55px, $right: 40px);
      display: none;
    }
  }
}

.header {
  &-links {
    text-transform: uppercase;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}