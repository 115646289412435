@import '../mixins';
@import '../variables';

.correct-title {
    @include font($family: $font-fibon-bold, $size: 24px, $color: $background-green);
    margin: 40px 0 40px 0;
  }
  
  .incorrect-title {
    @include font($family: $font-fibon-bold, $size: 24px, $color: $secondary);
    margin: 40px 0 40px 0;
  }