@import '../variables';
@import '../mixins';

.item {
  &-wrapper {
    margin-bottom: 50px;

    button {
      margin-top: 16px;
    }
  }

  &-title {
    @include font($color: $primary, $size: 24px, $family: $font-fibon-bold);
  }

  &-date {
    @include displayFlex($display: flex, $wrap: wrap);
    @include font($color: $black, $size: 20px);
    padding-top: 10px;
  }

  &-description {
    @include font($size: 16px, $color: $text-tertiary-pale);
    padding-top: 24px;
  }
}