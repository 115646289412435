@import '../variables';
@import '../mixins';

.item {
  &-wrapper {
    @include displayFlex($display: flex, $direction: column);
    line-height: 1.3;
    padding-left: 24px;
    margin-bottom: 15px;
    padding: 24px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    &--current {
      box-shadow: 0 0 20px $box-shadow;
      border-radius: 3px;
    }
    
    &:hover {
      box-shadow: 0 0 20px $box-shadow;
      border-radius: 3px;
      cursor: pointer;
    }
    &--notavailable {
      box-shadow: none!important;
      cursor: initial!important;
    }
  }

  &-button {
    z-index: 1;
    order: 1;

    @include media-breakpoint-down(md) {
      order: 2;
    }
  }
  &-unavaliable {
    @include font($size: 14px, $color: $black,$family: $font-fibon-bold);
    opacity: 0.5;
  }

  &-details {
    @include displayFlex($display: flex, $direction: column, $justify-content: center);
    margin-left: 20px;
    order: 2;

    button {
      margin-top: 20px;
    }

    &--wrapper {
      display: flex;
      padding-bottom: 20px;

      @include media-breakpoint-down(md) {
        @include displayFlex($display: flex, $justify-content: space-between);
      }
    }

    &--wrapper-simple {
      @include media-breakpoint-down(md) {
        padding-right: 20px;
      }
    }

    @include media-breakpoint-down(md) {
      margin: 0 20px 0 0;
      order: 1;
    }
  }

  &-number {
    @include font($size: 14px, $color: $text-tertiary-pale,$family: $font-fibon-medium);

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &-title {
    @include font($size: 12px, $weight: 600);

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  &-percent {
    @include font($size: 12px, $family: $font-fibon-bold,$color: $black);

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
}

.play-button {
  @include position($position: absolute, $top: 2px, $left: 2px);
  @include size($height: 36px, $min-width: 36px);
  border-radius: 50%;
  z-index: 10;
  background: $white;

  &-disabled {
    &:hover {
      pointer-events: none;
    }
  }

  &-current {
    @include position($position: absolute, $top: 3px, $left: 3px);
    @include size($min-width: 41px, $height: 41px);
    position: relative;
    border-radius: 50%;
  }

  &:hover {
    cursor: pointer;
  }

  &-wrapper {
    @include size($min-width: 40px, $height: 40px);
    display: flex;
    position: relative;

    &-current {
      @include size($min-width: 47px, $height: 47px);
    }
  }

  &-completion {
    @include position($position: absolute, $top: 0, $left: 0);
    z-index: 2;
    display: flex;
    width: 40px;
    height: 40px;
    background-image: conic-gradient(transparent 100%, transparent 0%);
    border-radius: 50%;

    &--current {
      @include size($min-width: 47px, $height: 47px);
    }

    &--background {
      @include position($position: absolute, $top: 1px, $left: 1px);
      @include size($width: 45px, $height: 45px);
      z-index: 1;
      display: flex;
      background: $border-grey;
      border-radius: 50%;

      &:after {
        @include position($position: absolute, $top: 1px, $left: 1px);
        background: $white;
      }
    }
  }
}
