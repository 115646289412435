@import '../variables';
@import '../mixins';

.accordion {
  &-wrapper {
    @include displayFlex($display: flex, $direction: column);
    border-bottom: 1px solid $border-grey;

    &:first-child {
      border-top: 1px solid $border-grey;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 -60px;
    }

    @include media-breakpoint-down(xm) {
      margin: 0 -30px;
    }

    @include media-breakpoint-down(xs) {
      margin: 0 -20px;
    }
  }

  &-title {
    @include displayFlex($display: flex, $direction: row, $justify-content: space-between);
    @include clickEffect();

    p {
      @include font($size: 18px, $family: $font-fibon-bold);
      color: $black;

      @include media-breakpoint-down(sm) {
        @include font($size: 16px, $family: $font-fibon-bold);
      }
    }

    i {
      visibility: hidden;

      @include media-breakpoint-down(lg) {
        visibility: visible;
        transform: rotate(90deg);
      }
    }

    &--active {
      i {
        transform: rotate(270deg);
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 25px;
    }
  }

  &-content {
    @include displayFlex($display: flex, $direction: column);
    visibility: visible;
    opacity: 1;
    height: 100%;

    @include media-breakpoint-down(lg) {
      visibility: hidden;
      height: 0;
      opacity: 0;
    }

    &--show {
      transition: all 0.3s ease-in;
      visibility: visible;
      opacity: 1;
      height: 100%;
      margin-bottom: 20px;
    }
  }
}
