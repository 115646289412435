@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: center, $wrap: wrap);
  @include size($width: 100%, $min-height: 100%);
  background: $secondary;
  position: relative;
  padding: 0 400px;

  @include media-breakpoint-down(xl) {
    padding: 0 300px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 200px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 100px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 50px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 25px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 18px;
  }

  &-primary {
    background: $primary;
  }

  form {
    width: 100%;
  }

  img {
    @include size($width: 212px, $height: 70px);
  }

  button {
    text-transform: uppercase;
  }
}

.logo-wrapper {
  @include displayFlex($display: flex, $justify-content: space-between, $direction: row, $align-items: center);
  margin: 80px 0 40px;
  cursor: pointer;

  @include media-breakpoint-down(xm) {
    margin: 20px 0 80px;
  }

  &--large {
    @include size($width: 213px, $height: 72px);

    @include media-breakpoint-down(xm) {
      display: none;
    }
  }

  &--small {
    @include size($width: auto, $height: 72px);

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.menu-button {
  @include position($position: absolute, $top: 35px, $right: 30px);
  z-index: 1200;
}

.header {
  @include media-breakpoint-down(xm) {
    width: 100%;
  }
}

.tabs {
  @include displayFlex($display: flex, $justify-content: center);
  @include clearOutline();
  @include font($size: 20px, $color: $white, $transform: uppercase);
  width: 100%;
  margin-bottom: 40px;

  @include media-breakpoint-down(xm) {
    @include displayFlex($display: flex, $justify-content: space-between);
  }

  span {
    @include clickEffect();
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &--active {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -6px;
      width: 100%;
      height: 2px;
      background: $white;
    }
  }
}

.back-button {
  @include displayFlex($display: flex, $justify-content: flex-start);
  margin: 50px 0 50px;
  width: 100%;
  color: $white;
  cursor: pointer;
  font-weight: normal;

  max-width: $globalMaxWidth;
}
