@import '../../mixins';

.breadcrumb-section{
    padding: 0;
    margin-bottom: 40px;
}

.header-section {
    text-align: center;
    padding: 0;
    max-width: 746px;
    width: 100%;
    margin-bottom: 60px;
    user-select: none;
    margin: 0 auto;

  &-title{
    margin-bottom: 32px;
    @include media-breakpoint-down(sm) {
      font-size: 38px !important;
    }
  }
}

.cooperations-section{
  margin-top: 80px;
  margin-bottom: 60px;
}

.subtitle{
  margin-bottom: 70px;
  text-align: center;
}