@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);

  h4 {
    margin-bottom: 15px;
    color: $black;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  img {
    @include size($width: 100%, $max-width: 300px);
    // border: 1px solid $border-grey-secondary;
    // border-radius: 3px;
    margin-bottom: 1rem;
  }

  span {
    margin-top: 20px;
  }
}
