@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);
  h4 {
    @include media-breakpoint-down(lg) {
      font-size: 20px;
    }
  }
}

.content {
  &-wrapper {
    padding: 20px 0 100px 0;

    @include media-breakpoint-down(md) {
      padding: 20px 0;
    }
  }
}

.quiz {
  &-wrapper {
    display: flex;
    padding-left: 24px;
    padding: 24px;
    
    @include media-breakpoint-down(md) {
      justify-content: space-between;
      padding-right: 20px;
    }
    
    &--current {
      padding: 24px;
      display: flex;
      box-shadow: 0 0 20px $box-shadow;
      border-radius: 3px;

      @include media-breakpoint-down(md) {
        justify-content: space-between;
      }
    }

    &:hover {
      box-shadow: 0 0 20px $box-shadow;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  &-button {
    @include size($height: 36px, $min-width: 36px);
    order: 1;
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      order: 2;
      margin: 0 0 20px 0;
    }

    &--enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-details {
    @include displayFlex($display: flex, $direction: column);
    order: 2;

    @include media-breakpoint-down(md) {
      order: 1;
    }
  }

  &-title {
    @include font($size: 12px, $color: $text-tertiary-pale, $line-height: 12px);

    @include media-breakpoint-down(md) {
      @include font($size: 14px, $color: $text-tertiary-pale, $line-height: 14px);
    }
  }

  &-description {
    @include font($size: 12px, $weight: 600);

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
}