@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: space-between);
  margin: 150px 0 70px;

  span {
    @include font($family: $font-fibon-bold, $size: 32px);
    margin-right: 16px;
  }

  a {
    @include font($size: 20px, $weight: 700);
    text-decoration: underline;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  @include media-breakpoint-down(xl) {
    margin-top: 120px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 100px;
  }


  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }
}
