@import 'src/assets/scss/_variables.scss';
@import '../../mixins';


.informed-card-section {
  background: $grey;
}

.form-section {
  background: $secondary;
}

.coachingCard {
  background: $primary;
}

.hero-section {
  padding: 0;
  margin-bottom: 140px;

  h1 {
    margin-top: 62px;

    @media screen and (max-width: 800px){
      font-size: 30px;
    }
  }

  p {
    margin-top: 48px;
  }

  button {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  img {
    max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-bottom: 40px;
  }
}

.home-video-section {
  width: 100%;
  max-width: 880px;
  margin: 0 auto 140px;

  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-bottom: 40px;
  }
}

.detail-section {
  padding: 0;
  margin-bottom: 140px;

  h2 {
    margin-bottom: 28px;
  }

  p {
    margin-bottom: 28px;
  }

  img {
    max-width: 100%;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-bottom: 40px;

    button {
      margin-bottom: 28px;
    }
  }

}

.team-section {
  padding: 100px 0;
  background-color: $secondary;
  color: $white;
  text-align: center;

  &-item {
    margin-top: 56px;
  }

  img {
    border-radius: 100%;
    height: 120px;
    width: 120px;
    object-fit: cover;
  }

  &-name {
    font-family: $font-fibon-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    margin: 20px auto 0;
  }

  &-role {
    margin-top: 12px;
    font-family: $font-fibon-medium;
  }
}

