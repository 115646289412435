@import '../mixins';
@import '../variables';


.container {
  @include media-breakpoint-down(lg) {
    margin-left: 50px;
  }
  @include media-breakpoint-down(md) {
    margin-left: 0px;
  }
 
  margin-left: 100px;
}
.payment-row {
  @include displayFlex($display: flex, $justify-content: space-between, $align-items: center);
  color: $primary;
}


.title {
  margin: 30px 0 30px 0;
  line-height: 44px;
}

.payment-wrapper {
  padding: 30px 30px 20px 30px;
  background-color: $white;
  color: $black;
  p{
    font-size: 20px;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
    @include media-breakpoint-down(xm) {
      font-size: 14px;
    }
  }
}

.line {
  height: 2px;
  background-color: $grey;
}

.wrapper {
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include size($width: 350px);

  box-shadow: 0px 0px 20px #00000029;
}

.summe {
  margin-bottom: 10px;
}

.forward-button {
  width: 100%;
}
