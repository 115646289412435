@import '../variables';
@import '../mixins';

.wrapper {
  @include size(100%, 100%);
  @include displayFlex(flex, center, center);
  @include position(fixed, 0, 0);
  @include blur(3px, $loader-background-color);

  z-index: 2000;
}
