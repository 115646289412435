@import 'mixins';
@import 'extends';

@import '@culturehq/add-to-calendar/dist/styles.css';

@include placeholderColor($primary);

html {
  scroll-behavior: smooth;
}

/* Base Elements */
.table,
a,
body,
h1,
h2,
h3,
html,
label,
ol,
p,
table,
ul {
  margin: 0;
  padding: 0;
}

body {
  @include font($family: $font-main, $weight: 500, $color: $primary, $line-height: 28px);
  background: $background;

  * {
    box-sizing: border-box;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font($family: $font-fibon-bold, $weight: 400);
}

h1 {
  font-size: 38px;
  line-height: 44px;

  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 42px;
  }

  &.headline1 {
    font-size: 42px;
    line-height: 54px;
    text-transform: uppercase;
  }
}

h2 {
  font-size: 32px;
  line-height: 44px;

  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 26px;
    line-height: 34px;
  }

  &.headline2 {
    font-size: 32px;
    line-height: 44px;
    text-transform: uppercase;
  }
}

h3 {
  font-size: 28px;
  line-height: 44px;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }

  &.headline3 {
    font-size: 26px;
    line-height: 34px;
  }

  &.highlight {
    font-size: 24px;
    line-height: 30px;
  }
}

h4 {
  font-size: 24px;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }

  &.headline4 {
    font-size: 22px;
    line-height: 30px;
  }
}

p {
  @include font($size: 20px, $weight: 500, $family: $font-main);
}

p.copy {
  @include font($size: 18px, $weight: 400, $family: $font-main);
  line-height: 28px;
  white-space: break-spaces;
}

p.small {
  @include font($size: 16px, $weight: 400, $family: $font-main);
  line-height: 24px;
}

p.highlight {
  @include font($size: 20px, $family: $font-fibon-medium, $line-height: 30px);
  white-space: break-spaces;
}

a {
  color: $primary;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  @include clearOutline();
  @include clickEffect();

  &.secondary {
    color: $secondary;
    text-decoration: underline;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input-group {
  display: flex;
  width: 100%;

  input {
    flex-grow: 1;
  }

  button {
    margin-left: 16px;
  }

  @include media-breakpoint-down(sm) { 
    flex-direction: column; 
    
    input {
      margin-bottom: 8px;
    }
    
    button {
      margin-left: 0;
    }
  }
}

input:not([type="checkbox"]) {
  @include clearOutline();
  border: 1px solid $primary;
  background: $white;
  border-radius: 4px;
  padding: 0 8px;
  height: 50px;
  @include font($size: 16px, $family: $font-main, $weight: 500);

  &.main {
    background-color: $background-secondary;
    border-color: $background-secondary;
    height: 54px;

    &::placeholder {
      opacity: 0.5;
    }
  }
}

textarea {
  @include clearOutline();
  background: $white;
  border: 1px solid $primary;
  border-radius: 4px;
  padding: 8px;
  height: 150px;
  resize: none;
  @include font($size: 18px, $weight: 400, $family: $font-main);
}

.chq-atc--dropdown {
  z-index: 999;
  bottom: 50px
}

button,
.chq-atc--button {
  @include font($size: 16px, $family: $font-fibon-bold);
  @include clearOutline();
  @include clickEffect();
  padding: 0 21px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 54px;
  line-height: 50px;
  background: $white;
  border: 2px solid $white;

  &.primary,
  .chq-atc--button {
    background: $primary;
    color: $white;
    border-color: $primary;

    &:hover {
      background: $blue;
      border-color: $blue;
    }
  }

  &.primary-small,  
  .chq-atc--button {

    background: $primary;
    color: $white;
    border-color: $primary;
    font-size: 14px;

    &:hover {
      background: $blue;
      border-color: $blue;
    }
  }

  &.secondary {
    background: $white;
    color: $secondary;
    border: 2px solid $secondary;

    &:hover {
      background: $secondary;
      color: $white;
    }
  }

  &.secondary-red {
    background: $secondary;
    color: $white;
    border: 2px solid $secondary;

    &:hover {
      background: $white;
      color: $secondary;
    }
  }

  &.tertiary {
    background: $white;
    color: $primary;
    border-color: $white;
  }

  &.disabled {
    background: $grey;
    border-color: $grey;
    color: $border-grey;
  }

  &:disabled {
    background: $grey;
    border-color: $grey;
    color: $border-grey;
    cursor: initial;

    &:hover {
      background: $grey;
      border-color: $grey;
      color: $border-grey;
      cursor: initial;
    }
  }
}

.video-holder {
  @include displayFlex($display: flex);

  .video {
    width: 70%;
    padding-right: 80px;

    @include media-breakpoint-down(lg) {
      padding-right: 40px;
    }

    @include media-breakpoint-down(md) {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }

  .public {
    width: 100% !important;
  }

  .playlist {
    overflow: auto;
    width: 30%;

    @include media-breakpoint-down(md) {
      display: none;
    }

    p {
      &:first-child {
        font-size: 16px;
        color: #7d7d7d;
      }
    }

    .playlist-contents {
      max-height: 800px;
      margin-bottom: 40px;
      overflow: auto;
      width: 100%;
  
      @include media-breakpoint-down(xxl) {
        max-height: 490px;
      }

      @include media-breakpoint-down(xl) {
        max-height: 325px;
      }

      @include media-breakpoint-down(lg) {
        max-height: 235px;
      }
    }
  }
}

main {

  flex: 1 0 auto;
  word-break: break-word;

  &.white {
    background: $white;
  }
}

.max-width {
  @include media-breakpoint-up(lg) {
    width: $globalMaxWidth !important;
    margin: auto;
  }
}

span {
  &.secondary {
    background-color: $secondary;
  }
}

section {
  @extend .general-padding;

  &.no-padding {
    @extend .section-no-padding;
  }

  &.no-padding-md {
    margin-bottom: 1rem;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &.white {
    background: $white;
  }

  &.grey {
    background: $grey;
  }

  &.primary {
    background: $primary;
  }

  &.secondary {
    background: $secondary;
  }

  &.small {
    @extend .general-small-padding;

    &.no-padding {
      @extend .section-no-padding;
    }
  }

  &.small-padding-left {
    @extend .general-small-padding-left;
  }
}

.bg-secondary {
  background-color: $secondary;
}

.full-width {
  width: 100%;
}

.app-wrapper {
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.page-not-found {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center);
  font-size: 50px;
}

.react-dropdown-select {
  @include clearOutline();
  border: 1px solid $primary;
  background: $white;
  height: 50px;
  padding: 0 8px !important;
  border-radius: 4px !important;

  &-input {
    margin: 0 !important;
    border: none !important;
  }

  &-dropdown {
    color: $primary;
  }

  span {
    @include displayFlex($display: flex, $align-items: center);
    font-size: 16px;
    flex-grow: 1;
  }
}

.register-form .react-dropdown-select {
  @include clearOutline();
  @include placeholderColor($white);
  border: 1px solid $white !important;
  background: transparent;
  color: $white;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.errors {
  color: $secondary;
}

.base-title {
  h2 {
    font-size: 48px;
    @include media-breakpoint-down(md) {
      font-size: 40px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }
}

.margin-left-10 {
  margin-left: 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.full-container {
  background-color: $background;
  padding: 0;
}

.text-center {
  text-align: center;
}

.pos-relative {
  position: relative;
}

.chq-atc {
  width: 100%;
}

.chq-atc--button {
  width: 100%;
  color: $primary;
  margin-top: 1rem;
}

.chq-atc--button path {
  fill: $primary;
}