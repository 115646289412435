@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/mixins';

.newsletter-section {
  background-color: $white;
  padding: 140px 0px;
  text-align: center;

  p {
    margin: 32px auto 0;
    max-width: 940px;
  }

  form {
    max-width: 560px;
    margin: 32px auto 0;
    display: flex;
  }

  &-left-image {
    position: absolute;
    top: -100px;
    left: 0;

    @include media-breakpoint-down(md) {
      top: -130px;
    }
  }

  &-right-image {
    position: absolute;
    bottom: -100px;
    right: 0;

    @include media-breakpoint-down(md) {
      bottom: -130px;
    }
  }

  .email-label{
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
}