@import '../variables';
@import '../mixins';

.cookie {
  &-container {
    @include position($position: fixed, $bottom: 0, $left: 0);
    @include size($width: 100%, $min-height: 100px, $max-height: 70%);
    display: flex;
    z-index: 9999;
    background: $primary;
    box-shadow: 0 -2px 2px 2px rgba(0, 0, 0, .08);
    opacity: 0.9;
  }

  &-wrapper {
    @include size($width: 100%, $height: 100%, $max-width: 1140px);
    @include displayFlex($display: flex, $align-items: center, $direction: row, $wrap: wrap);
    padding: 16px;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
      max-width: 960px;
    }

    @include media-breakpoint-down(md) {
      max-width: 720px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 540px;
    }
  }
}

.text-wrapper {
  margin-top: 24px;
  padding-right: 16px;
  width: 100%;
  color: $white;

  h2 {
    margin-bottom: 16px;
  }
}

.check-boxes-wrapper {
  @include displayFlex($display: flex, $direction: row, $wrap: wrap);
  padding: 16px 16px 0 0;

  span {
    @include size($width: auto, $height: auto, $min-height: 14px, $max-height: 50%);
    @include position($position: relative, $top: 0, $left: 0);
    @include font($size: 12px, $color: $text-tertiary);
    display: inline-block;
    z-index: 1;
    cursor: default;
    vertical-align: top;

    label {
      padding: 0 8px;
    }

    input {
      vertical-align: middle;
    }
  }
}

.consent {
  @include displayFlex($display: flex, $justify-content: space-between);
  padding-top: 16px;
  width: 100%;

  &-privacy {
    margin-bottom: 20px;
    display: flex;

    a {
      @include font($size: 10px, $color: $white);
      margin-left: 0;
      margin-bottom: -30px;
      text-decoration: underline;
    }
  }

  &-buttons {
    justify-content: flex-end;
  }
}
