@import '../variables';
@import '../extends';
@import '../mixins';

.wrapper {
  @extend .section-top-bottom-padding;

  h2 {
    word-spacing: 10px
  }

  @include media-breakpoint-down(xl) {
    padding-right: 150px;
  }

  @include media-breakpoint-down(lg) {
    padding-right: 150px;
  }

  @include media-breakpoint-down(md) {
    padding-right: 0;
  }

  p {
    line-height: 30px;
  }
}

.card-title {
  margin-bottom: 25px;
  font-size: 32px;
  text-transform: uppercase;
}

.wrapper-no-bottom-padding {
  @extend .section-top-bottom-padding;
  padding-right: 300px;

  padding-bottom: 0%;

  h2 {
    word-spacing: 10px
  }
  
  @include media-breakpoint-down(xl) {
    padding-right: 250px;
  }

  @include media-breakpoint-down(lg) {
    padding-right: 150px;
  }

  @include media-breakpoint-down(md) {
    padding-right: 0;
  }

  p {
    line-height: 30px;
  }
}
