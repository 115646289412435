@import '../mixins';
@import '../variables';

.presentation-wrapper {
  padding: 0;
  margin-bottom: 140px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 64px;
  }

  h2 {
    margin-top: 54px;
  }

  &-description {
    margin-top: 32px;
  }

  button {
    margin-top: 28px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 28px;
      width: 100%;
    }
  }

  img {
    width: 100%;
  }
}
