@import '../mixins';
@import '../variables';

.wrapper {
    margin-top: 70px;
}
.card-title {
    margin-bottom: 30px;
}
.card-description {
    @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin-bottom: 30px;
    }
    max-width: 60%;
    margin-bottom: 60px;
}