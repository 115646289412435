@import '../variables';
@import '../mixins';

.back-button {
  @include displayFlex($display: flex, $align-items: center);

  @include media-breakpoint-down(sm) {
    margin: 30px 0px 20px;
  }

  color: $secondary;
  font-size: 16px;
  cursor: pointer;
  margin: 60px 0px 30px;

  p {
    font-size: 16px;
    margin-left: 13px;
  }
}

.container {
  margin: 24px 0;
  @include displayFlex($display: flex, $align-items: center, $justify-content: space-between);

  @include media-breakpoint-down(sm) {
    @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: space-between);
    min-height: 100vh;
  }


  p {
    margin: 12px 0;
  }

  button {
    margin-bottom: 24px;
  }

  img {
    @include size($width: 50%, $height: auto);

    @include media-breakpoint-down(sm) {
        @include size($width: 100%, $height: auto);
    }

    border-radius: 10px;
    margin: 24px 0;
  }
}

.image-row {
    @include displayFlex($display: flex, $align-items: center, $justify-content: center);
}