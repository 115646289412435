@import '../variables';
@import '../mixins';

.wrapper {
  width: 100%;
  position: relative;
}

.icon {
  &-wrapper {
    position: absolute;
    @include displayFlex($display: flex, $justify-content: center, $align-items: center);
    @include size($width: 82px, $height: 82px);
    box-shadow: 0 1px 5px #00000029;
    border-radius: 100%;
    background: $white;
    cursor: pointer;
    z-index: 10;

    &:hover {
      background: $secondary;

      i {
        color: $white;
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    i {
      @include font($size: 25px, $color: $secondary, $weight: 200);
    }
  }

  &-right {
    @include position($left: 99%, $top: calc(50% - 41px));
  }

  &-left {
    @include position($right: 105%, $top: calc(50% - 41px));

    i {
      transform: rotate(180deg);
    }
  }
}

.slider-wrapper {

  @include media-breakpoint-down(lg) {
    width: calc(100vw - 177px);
  }

  @include media-breakpoint-down(md) {
    width: calc(100vw - 117px);
  }

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 57px);
  }

  @include media-breakpoint-down(xm) {
    width: calc(100vw - 37px);
  }

  & > div {
    height: 100% !important;
  }
}

.slider-wrapper-profile {
  @include media-breakpoint-down(xl) {
    width: calc(100vw - 217px);
  }

  @include media-breakpoint-down(lg) {
    width: calc(100vw - 177px);
  }

  @include media-breakpoint-down(md) {
    width: calc(100vw - 117px);
  }

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 57px);
  }

  @include media-breakpoint-down(xm) {
    width: calc(100vw - 37px);
  }

  & > div {
    height: 100% !important;
  }
}

.coachings-wrapper {
  width: 100%;
  position: relative;

  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

.slider-wrapper-coachings {
  @include media-breakpoint-down(lg) {
    width: calc(100vw - 177px);
  }

  @include media-breakpoint-down(md) {
    width: calc(100vw - 117px);
  }

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 57px);
  }

  @include media-breakpoint-down(xm) {
    width: calc(100vw - 37px);
  }

  & > div {
    height: 100%;
  }
}

.icon-coaching {
  &-right {
    @include position($left: 99%, $top: calc(50% - 41px));
  }

  &-right-coaching-page {
    @include position($left: 100%, $top: calc(50% - 41px));
  }

  &-left {
    @include position($right: 105%, $top: calc(50% - 41px));

    i {
      transform: rotate(180deg);
    }
  }

  &-left-coaching-page {
    @include position($right: 100%, $top: calc(50% - 41px));

    i {
      transform: rotate(180deg);
    }
  }
}