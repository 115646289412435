@import '../mixins';
@import '../variables';

.gray-line {
  @include size($width: 100%, $height: 1px);

  background-color: $border-grey-secondary  ;
  margin: 30px 0px 30px;
}

.module {
  @include font($size: 16px, $color: $primary);
  margin-bottom: 16px;
}

.drop-line {
  @include displayFlex($display: flex, $align-items: center, $justify-content: space-between);
}

.collapse {
  transition: height 0.3s ease-in-out;
}

.text {
  margin-top: 1rem;
  width: 90%;
  font: $font-secondary !important;
}
.image {
  cursor : pointer;
}

.moduleTitle{
  width: 90%;
  line-height: 30px;
}

.module-book {
  margin-top: 20px;
}

.collapsible-area {
  cursor: pointer;
}