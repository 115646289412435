@import '../mixins';
@import '../variables';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $justify-content: space-between, $grow: 1);
  height: 100%;
  width: 100%;
  text-align: left;
  border-radius: 20px;
  background-color: $white;
  margin: 0;
  border: 1px solid $border-grey-secondary;

  figure {
    @include image-aspect(16, 10, 100%);

    img {
      border-radius: 20px 20px 0 0;
    }
  }
}

.coaching-title {
 color: $primary;
}
.coaching-description {
  color: $primary;
  height: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;

  margin-top: 20px;
  margin-bottom: 20px;
}

.left-button {
  margin-top: auto;
  margin-right: 10px;
  margin-bottom: 30px;
}
.right-button {
  margin-bottom: 30px;
}

.details-wrapper {
  @include displayFlex($display: flex, $direction: column, $justify-content: space-between, $grow: 1);
  color: $black;
  padding: 30px 30px 0 30px;
}

.costTag {
    @include position($position: absolute, $top: 0, $left: 0);
    @include font($size: 16px, $family: $font-fibon-bold, $color: $white);
    background: $primary;
    border-radius: 20px 0 20px;
    padding: 6px 30px 6px 30px;
    z-index: 10;
}

.freeTag {
  @include position($position: absolute, $top: 0, $left: 0);
  @include font($size: 16px, $family: $font-fibon-bold, $color: $white);
  background: $secondary;
  border-radius: 20px 0 20px;
  padding: 6px 30px 6px 30px;
  z-index: 10;
}