@import '../variables';
@import '../mixins';

.form {
  &-label {
    color: $white;
    width: 100%;
    margin-bottom: 8px;
  }

  &-select {
    margin-bottom: 20px;
  }

  &-small-input {
    @include displayFlex($display: flex, $justify-content: space-between, $wrap: wrap);
    width: 100%;

    & > span {
      @include displayFlex($display: flex, $direction: column);
      width: calc(50% - 12px);

      @include media-breakpoint-down(xm) {
        width: 100%;
      }
    }

    label {
      width: 100%;
    }
  }

  &-large-input {
    @include displayFlex($display: flex, $direction: column);

    & > span {
      @include displayFlex($display: flex, $direction: column);
      width: 100%;
    }

    label {
      width: 100%;
    }
  }

  &-error {
    @include font($size: 12px, $color: $white);
    margin: -10px 0 20px 0;
  }

  input {
    border: 1px solid $white;
    background: transparent;
    margin-bottom: 20px;
    color: $white;
  }
}

.terms-wrapper {
  @include displayFlex($display: flex, $justify-content: space-between, $direction: row);

  @include media-breakpoint-down(md) {
    @include displayFlex($display: flex, $justify-content: space-between, $direction: column);
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}

.acceptance {
  @include displayFlex($display: flex, $align-items: center);
  @include size($width: 100%, $height: 50px);
  color: $white;

  input {
    @include size($width: 25px, $height: 100%);
    margin: 0 10px 0 0;
    border: 1px solid $border-grey;
    border-radius: 5px;
  }
}

.submit-button {
  color: $primary;
  width: 50%;
  font-size: 18px;
  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}