@import '../mixins';

.wrapper {
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }

  margin-top: 100px;
}

.title {
  margin: 15px 0px 30px;
}

.row {
  @include displayFlex($display: flex, $direction: row);

  margin-top: 15px;
}

.icon {
  @include size($width: 28px, $height: 28px);

  margin-right: 25px;
}
