@import '../../../mixins';
@import '../../../variables';

.module-card {
  width: 100%;
  padding: 0;
  display: flex;

  &-image-wrapper {
    flex: 0 0 164px;
    width: 164px;
    height: 164px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-badge {
      position: absolute;
      border-radius: 20px 20px 0px 0px;
      background-color: $primary;
      color: $white;
      left: 0;
      top: 0;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font($size: 18px, $family: $font-fibon-medium);
      
      &-secondary {
        @extend .module-card-image-wrapper-badge;
        background-color: $secondary;
      }
    }
  }

  &-content-wrapper {
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin-right: 32px;
      margin-bottom: 20px;
    }
    button {
      width: fit-content;
    }
  }
}