@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/mixins';

.coaching-offers-section {
  padding: 0;
  margin-bottom: 170px;

  h2 {
    margin-bottom: 28px;
  }

  &-text {
    margin-bottom: 60px;
  }

  &-item {
    margin-bottom: 44px;
  }

  &-slider {
    & > div {
      height: 100% !important;
    }
  }
}