@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $wrap: wrap);
}

.courses-wrapper {
  @include displayFlex($display: flex, $wrap: wrap);
}

.interested-wrapper {
  @include displayFlex($display: flex, $wrap: wrap);
}
