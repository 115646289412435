@import 'variables';
@import 'mixins';

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  display: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  @include position($position: fixed, $top: 0, $right: 0);
  @include size($height: 100%, $min-width: 320px !important);

  @include media-breakpoint-up(xl) {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    @include size($height: 100%, $min-width: 320px !important, $width: 100% !important);
    @include position($position: fixed, $top: 90px, $right: 0);
  }
}

/* General sidebar styles */
.bm-menu {
  background: $background-secondary;
  padding: 100px 25px 0;
  font-size: 1.15em;

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

/* Individual item */
.bm-item {
  @include displayFlex($display: flex !important, $direction: column);
}

/* Styling of overlay */
.bm-overlay {
  @include position($top: 0, $left: 0);
  background: $background-secondary;

  @include media-breakpoint-up(xl) {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    @include position($position: fixed, $top: 90px, $right: 0);
  }
}
