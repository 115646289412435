@import '../../../mixins';
@import '../../../variables';

.multiselect {
    min-width: 230px;
}

.checkbox-wrapper{
    @include displayFlex($display: flex, $align-items: center);
    margin: 8px;

    input {
        @include size($width: 25px, $height: 18px);
        margin: 0 10px 0 0;
        border: 1px solid $border-grey;
        border-radius: 5px;
    }
}