@import '../variables';
@import '../mixins';

.filter-section {
  @include displayFlex($display: flex, $direction: column, $wrap: wrap);

  h2 {
    text-transform: uppercase;
  }

  h4 {
    margin: 10px 0 0;
  }

  &-row {
    margin-top: 25px;
    margin-bottom: 72px;
  }

  &-col{
    margin-top: 10px;
  }
  
}

