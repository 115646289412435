@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row, $justify-content: center);
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  button {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    margin: 0 20px 15px 0;
  }

  h1 {
    margin: 60px 0 30px 0;
  }

  .question-count {
    margin-top: 30px;
  }

  img {
    @include media-breakpoint-down(md) {
      order: 1;
      margin: 0 100px 0 100px;
      width: auto;
    }
    @include media-breakpoint-down(sm ) {
      margin: 0 50px 0 50px;
    }
    @include media-breakpoint-down(xm) {
      margin: 0;
    }
    padding: 20px;
    width: 30%;
    order: 2;
  }
}

.left-container {
  @include media-breakpoint-down(md) {
    order: 2;
    width: 100%;
  }
  width: 70%;
  order: 1;
}
