@import '../variables';
@import '../mixins';

.buttons-wrapper {
  margin-top: 40px;
}

.no-courses-wrapper {
  margin-top: 40px;
}

.course-schedule {
    &-title {
      @include font($transform: uppercase, $line-height: 28px);
      margin-top: 80px;
    }

    &-description {
      font-size: 16px;
      margin-top: 40px;

      &:after {
        content: '*';
        color: $secondary;
      }
  }
}

.dates-wrapper {
  @include displayFlex($display: flex, $wrap: wrap);
}

.code {
  &-input {
    @include displayFlex($display: flex, $direction: column);
    margin-top: 10px;
  }

  &-title {
    font-size: 16px;
    margin-top: 20px;

    &:after {
      content: '*';
      color: $secondary;
    }
  }
}

.mandatory-field {
  font-size: 16px;
  margin: 40px 0 80px;

  &:before {
    content: '*';
    color: $secondary;
    margin-right: 4px;
  }
}

.form-error {
  @include font($size: 12px, $color: $secondary);
  width: 100%;
}

.checkbox {
  &-acceptance {
    // margin-bottom: 1rem;
    @include displayFlex($display: flex, $align-items: center);

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 4rem;
    }

    margin-top: 16px;

    input {
      margin: 0 25px 0 0;
      border-radius: 2px;
      transform: scale(1.7) translate(3px, 3px);

    }
  }

  &-check {
    margin-bottom: 1.2rem;
    font-size: 12px;
  }
}