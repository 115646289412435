@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/mixins';

.perks-section {
  padding: 120px 0;
  background-color: $primary;
  color: $white;

  &-item {
    display: flex;
    align-items: flex-start;
    margin-top: 60px;

    img {
      margin-right: 20px;
      max-width: 67px;
      flex: 0 0 67px;
    }

    h3 {
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    p {
      font-weight: 300;
    }
  }
}