@import '../variables';
@import '../mixins';

.container {
  margin: 50px 0px 80px;
}

.text {
  margin-top: 30px;
}

.title {
  margin-bottom: 30px;
  line-height: 44px;
  text-transform: uppercase;
}

.wrapper {
  @include displayFlex($display:flex , $direction: row);
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}


