@import '../mixins';
@import '../variables';

.wrapper {
  @include displayFlex($display: flex, $direction: column);

  @include media-breakpoint-down(md) {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin: 50px 0px 50px;
  }


  background-color: $background-secondary;
  border-radius: 6px;
  margin: 100px 0px 100px;
}

.title {
  @include displayFlex($display: flex, $align-items: center);

  margin: 45px 0px 20px;
}

.imageContainer {
  @include size($width: 28px, $height: 28px);

  @include displayFlex($display: flex, $justify-content: center, $align-items: center);

  margin-right: 15px;
}

.element {
  margin: 0px 30px 50px 30px;
}

.button {
  width: 100%;
  margin-top: 30px;
}
