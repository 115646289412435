@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: center, $wrap: wrap, $direction: column, $align-items: center);
  @include size($width: 100%, $min-height: 100%);
  background: $primary;
  color: $white;
  padding: 0 400px;
  text-align: center;

  @include media-breakpoint-down(xl) {
    padding: 0 300px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 200px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 100px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 50px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 25px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 18px;
  }
}

.buttons-wrapper {
  margin-top: 50px;

  button {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
