@import '../variables';
@import '../mixins';

.wrapper {
  margin: 0 auto;

  .icon {
    @include size($width: 30px, $height: 30px);
  }

  .dot {
    @include size($width: 11px, $height: 11px);

    &-left {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      margin-right: 10px;
    }

    &-right {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      margin-left: 10px;
    }

    &-active {
      background-color: $primary;
    }

    background-color: $background-secondary;
    border-radius: 50%;
  }

  .step-indicator {
    @include displayFlex($display: flex, $align-items: center);

    margin-top: 0px;
    padding: 0 0px;
  }

  .step {
    @include displayFlex($display: flex, $align-items: center, $direction: column);

    position: relative;
    z-index: 1;
  }

  .step-icon {
    @include font($size: 20px, $text-align: center, $line-height: 30px, $color: $primary);

    @include size($width: 30px, $height: 30px);

    @include media-breakpoint-down(sm) {
      @include size($width: 20px, $height: 20px);
      @include font($size: 16px, $text-align: center, $line-height: 20px, $color: $primary);
    }
    border-radius: 50%;
    background: $background-secondary;
    position: relative;
  }

  .step.active .step-icon {
    background: $primary;
    color: white;
  }

  .step p {
    @include font($size: 12px, $text-align: center, $word-break: normal);

    @include media-breakpoint-down(sm) {
      font-size: 10px;
      margin-top: 20px;
    }

    position: absolute;
    color: $background-secondary;
    margin-top: 30px;
  }

  .step.active p {
    color: $primary;
  }

  .step.step2 p,
  .step.step3 p {
    left: 50%;
    transform: translateX(-50%);
  }

  .indicator-line {
    @include size($width: 100%, $height: 2px);

    flex: 1;
    background: $background-secondary;
  }

  .indicator-side-line {
    @include size($width: 20px, $height: 2px);

    background: $background-secondary;
    &-active {
      background: $primary;
    }
  }

  .indicator-line.active {
    background: $primary;
  }
}
