@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $wrap: wrap);

  @include media-breakpoint-down(md) {
    margin-top: 50px;
  }

  h4 {
    margin: 0 0 25px 0;
  }

  &-live {
    margin-top: 50px;
  }
}
