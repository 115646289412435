@import 'src/assets/scss/extends';
@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.wrapper {
  @include displayFlex($display: flex);
  color: $white;
  background: $secondary;
  padding: 190px 0;

  h2 {
    word-spacing: 10px
  }

  @include media-breakpoint-down(lg) {
    padding: 160px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 100px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 0;
  }

  &-bubble {
    @include displayFlex($display: flex, $align-items: flex-start);
    width: 37%;
    margin-right: 5%;

    img {
      @include size($width: 100%);
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.form {
  margin: 0 0 0 0;

  @include media-breakpoint-down(md) {
    margin: 0 10% 0 5%;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 20px;
  }

  &-error {
    @include font($size: 12px, $color: $white);
    margin: -10px 0 20px 0;
    
    &-smallMargin {
      @include font($size: 12px, $color: $white);
      margin: -40px 0 20px 0;
    }
  }

  &-header {
    @extend .p-font-size;

    h2 {
      text-transform: uppercase;
      margin-bottom: 40px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
    }

    p {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
    }
  }

  &-content {
    input {
      color: $white;
      background: transparent;
      border: 1px solid $white;
      margin: 10px 0 22px;
    }

    label {
      font-size: 16px;
    }

    &-input {
      &--small {
        @include displayFlex($display: flex, $direction: row, $justify-content: space-between);

        @include media-breakpoint-down(xm) {
          @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
        }

        & > span {
          @include displayFlex($display: flex, $direction: column);
          width: 48%;

          @include media-breakpoint-down(xm) {
            width: 100%;
          }
        }
      }

      &--large {
        @include displayFlex($display: flex, $direction: column);

        input {
          width: 100%;
        }

        textarea {
          background: transparent;
          border: 1px solid $white;
          color: $white;
          width: 100%;
          resize: none;
          margin: 10px 0 20px;
        }
      }
    }
  }

  button {
    width: 100%;
    color: $secondary;
    text-transform: uppercase;
  }
  
  .gdpr {
    
    &-acceptance {
      // margin-bottom: 1rem;
      @include displayFlex($display: flex, $align-items: center);
      @include size($width: 100%, $height: 100px);
      color: $white;
  
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      @include media-breakpoint-down(sm) {
        display: contents;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 4rem;
      }

      input {
        @include size($width: 50px, $height: 100%);
        margin: 0 25px 0 0;
        border: 1px solid $border-grey;
        border-radius: 5px;

        @include media-breakpoint-down(sm) {
          @include size($width: 35px, $height: 35px);
        }
      }
    }

    &-check {
      margin-bottom: 1.2rem;
      font-size: 12px;
    }
  }

  @include placeholderColor($color: $white, $opacity: 0.7);

}
