@import '../mixins';
@import '../variables';

.wrapper {
  margin-bottom: 32px;
}
.card-wrapper {
  @include displayFlex($display:flex, $wrap: wrap, $direction: row);
}

.title {
  margin: 30px 0px 30px;
}

.radio input[type='radio'] {
  display: none;
}

.radio label:before {
  @include size($width: 15px, $height: 15px);

  @include position($position: relative, $top: 3px);

  content: ' ';
  display: inline-block;
  margin: 0px 5px 0 0;
  border-radius: 50%;
  border: 1px solid $primary;
  background-color: $white;
}

.radio input[type='radio']:checked + label:before {
  border: 1px solid $secondary;
}

.radio input[type='radio']:checked + label:after {
  @include size($width: 9px, $height: 9px);

  @include position($position: absolute, $top: 35px, $left: 20px);

  border-radius: 50%;
  content: ' ';
  display: block;
  background: $secondary;
}

.card {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-right: 0px;
  }

  @include displayFlex($display: flex, $align-items: center);

  @include size($width: 350px, $height: 80px);

  background-color: $grey;
  justify-content: space-between;
  position: relative;
  margin: 15px 30px 0px 0px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 16px 0px 16px;
}

.card-checked {
  background-color: #ff55600d;
  border: 1px solid $secondary;
}

.inputField {
  @include size($width: 350px, $height: 56px);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  color: $primary;
  background-color: $grey;
  border: 0px;
  margin: 10px 0px 10px;
  border-radius: 4px;
}

.error-text {
  font-size: 12px;
  min-height: 20px;
  word-break: initial;
}

.button {
  @include size($width: 350px, $height: 56px);
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
