@import '../variables';
@import '../extends';
@import '../mixins';

.social {
  
    &-wrapper{
        z-index: 100;
        background: $white;
        border-radius: 0 0 2px 2px;

        position: fixed;
        width: fit-content;
        padding: 16px 12px;
        top: 50%;
        transform: translateY(-50%);
        transition-property: all; 
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 500ms;
        box-shadow: 0 0 20px $box-shadow;

        @include media-breakpoint-down(lg) {
          display: none;
        }
    
      }

    &-items {
        display: flex;
        flex-direction: column;
        gap: 6px;
    
        &-logo {
          width: 28px;
          height: 28px;
        }
      }
}