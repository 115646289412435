@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
  background-color: $secondary;
  color: $white;
  padding-top: 180px;
  padding-bottom: 130px;
  position: relative;
}

.title{
  max-width: 746px;
  width: 100%;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 40px;
  text-align: center;
}

.description{
  max-width: 746px;
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
}

.center {
  @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
}

.image {
  position: absolute;
  padding: 45px 0;
  left: 0;
  top: 0;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
}