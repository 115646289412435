@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex);
  border: 1px solid $primary;
  border-radius: 4px;
  margin: 10px 10px 0 0;
  padding: 15px;
  cursor: pointer;
  min-width: 319px;

  &-waiting {
    border: 1px dashed $black;
  }

  &:hover {
    background: $background-secondary;
  }

  &-selected {
    background: $primary;
    color: $white;

    .dot {
      height: 7px;
      width: 7px;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
      margin-left: 2px;
      margin-top: 2px;
    }

    &:hover * {
      color: $primary;
    }
  }

  &-NoPointerEvents {
    cursor: not-allowed;
  }
}

.label {
  &-wrapper {
    @include displayFlex($display: flex, $align-items: center);
    padding-right: 15px;

    label {
      @include size($width: 13px, $height: 13px);
      border-radius: 100%;
      border: 1px solid $primary;
    }

    &--selected {
      label {
        border: 1px solid $white;
        background: $white;
      }
    }
  }
}

.event {
  &-wrapper {
    @include displayFlex($display: flex, $direction: column);
  }

  &-date {
    @include font($size: 20px, $weight: 600);
    color: $primary;
    &-selected {
      color: $white;
    }
  }
}
