@import '../variables';
@import '../mixins';

.wrapper {
  margin: 75px 0;

  @include media-breakpoint-down(md) {
    margin: 35px 0;
  }

  input {
    @include size($width: 100%, $height: 25px);
    border: none;
    border-bottom: 1px solid $primary;
    border-radius: 0;
    background: $background;
    padding-left: 0;
    height: 35px;
  }
}

.avatar {
  @include size($width: 190px, $height: 190px);
  @include media-breakpoint-down(md) {
    left: 50%;
    transform: translateX(-50%);
  }
  position: relative;
  margin-bottom: 50px;
  &-image {
    @include size($width: 100%, $height: 100%);
    background: $primary;
    border-radius: 50%;
    @include displayFlex($display: flex, $align-items: center, $justify-content: center);
    color: $white;

    span {
      @include font($size: 70px, $transform: uppercase);
    }

    img {
      @include size($width: 100%, $height: 100%);
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-edit {
    @include position($position: absolute, $right: 0, $top: 150px);
    @include size($width: 36px, $height: 36px);
    @include displayFlex($display: flex, $align-items: center, $justify-content: center);
    background: $primary;
    border-radius: 50%;

    input {
      display: none;
    }
  }
}

.profile-items {
  height: 32px;
  padding-bottom: 35px;
  padding-top: 10px;
  border-bottom: 1px solid $grey;
  cursor: pointer;

  &:nth-child(2) {
    margin-top: 74px;
  }

  &:nth-child(4) {
    span {
      color: $secondary;
    }
  }
  &:last-child {
    border-bottom: none;
  }

  span {
    @include clickEffect();
    color: $black;
    font-weight: 400;
  }

  &-selected {

    span {
      font-weight: 700;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.dropdown-profile-items {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
    padding: 24px 0;
  }
}

.wrapper-base-card {
  background: $secondary;
  position: relative;

  article {
    color: $white;
    padding: 53px;
    text-transform: initial;
  }

  i {
    @include position($position: absolute, $right: 24px, $top: 24px);
    cursor: pointer;
    font-size: 20px;
  }

  @include media-breakpoint-down(md) {
    article {
      h2 {
        text-transform: initial;
      }
    }
  }
}
