@import '../variables';
@import '../mixins';

.breadcrumbs {
  &-wrapper {
    margin-top: 50px;
  }

  &-item {
    span {
      color: $secondary;
      cursor: pointer;
    }

    &--last {
      span {
        color: $primary;
        pointer-events: none;
      }

      i {
        display: none;
      }
    }

    i {
      color: $primary;
      cursor: default;
      pointer-events: none;
      font-size: 10px;
      padding: 0 12px;
    }
  }
}
