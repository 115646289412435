.main-title {
  margin: 30px 0px 30px;
}

.secondary-title {
  margin: 50px 0px 50px;
}
.image {
  cursor: pointer;
  z-index: 100;
  &-container {
    position: relative;
  }
}
.image:hover {
 
  background-color: white;
  filter: grayscale(0.1)  opacity(0.60);
}

.arrows {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px !important;
  height: 50px !important;
  border-radius: 0 !important;
  visibility: hidden;
  z-index: 1000;
}
.image:hover + .arrows {
  visibility: visible;
}

.small {
    font-size: 16px;
}