@import '../mixins';
@import '../variables';

.wrapper {
  margin: 100px 0px 100px;
}

.title {
  margin-bottom: 30px;
  text-transform: uppercase;
}

.button {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  @include size($width: 300px, $height: 56px);
}

.inputField {
  color: $primary;
  background-color: $grey;
  border: 0px;
  width: 100%;
  margin: 10px 0px 10px;
  border-radius: 4px;
}

.inputField::placeholder {
  opacity: 0.4;
}

.plz-ort-container {
  @include displayFlex($display: flex, $direction: row);

  width: 100%;
}

.plz-container {
  width: 35%;
  margin-right: 25px;
}

.ort-container {
  width: 65%;
}

.required-info {
  @include displayFlex($display: flex, $align-items: center);

  color: $secondary;
  margin-top: 15px;

  p {
    color: $black;
    font-size: 16px;
  }
}

.error-text {
  font-size: 12px;
  min-height: 20px;
  word-break: initial;
}

.toggle {
  margin-bottom: 2rem;
}

.toggleText {
  margin-top: 2rem;
}

.billingAddressText {
  margin-bottom: 2rem;
}