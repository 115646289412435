@import '../variables';
@import '../extends';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center, $wrap: wrap, $direction: column);
  @extend .section-top-bottom-padding;
  @extend .p-font-size;

  h2 {
    word-spacing: 10px
  }
}

.details {
  &-err {
    &-big {
      @include media-breakpoint-down(md) {
       display: lg;
      }
      @include media-breakpoint-down(md) {
       display: none;
      }
  
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    &-small {
      display: none;
      @include media-breakpoint-down(md) {
       display: contents;
      }
  
      @include media-breakpoint-down(sm) {
        display: contents;
      }
    }
  }
  &-title {
    @include font($transform: uppercase, $text-align: center);
    padding-bottom: 25px;
  }

  &-content {
    max-width: 60%;
    margin-bottom: 44px;
    text-align: center;

    @include media-breakpoint-down(lg) {
      max-width: 80%;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  &-email {
    display: flex;
    width: 50%;

    @include media-breakpoint-down(lg) {
      display: flex;
      width: 80%;
    }
    
    @include media-breakpoint-down(md) {
      display: flex;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      display: grid;
      width: 100%;
    }

    input {
      flex-grow: 1;
      border-radius: 4px 0 0 4px;
      color: $primary;
      font-size: 18px;
    }

    button {
      @include font($transform: uppercase);
      border-radius: 0 4px 4px 0;

      @include media-breakpoint-down(lg) {
        border-radius: 0 4px 4px 0;
      }
      
      @include media-breakpoint-down(md) {
        border-radius: 0 4px 4px 0;
      }
  
      @include media-breakpoint-down(sm) {
        border-radius: 4px 4px 4px 4px;
        margin-top: 0.25rem;
      }
    }
  }
}
