@import '../variables';
@import '../extends';
@import '../mixins';

.wrapper {
  @include size($width: 25px, $height: 20px);
  z-index: 1200;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &-login {
    span {
      @include clickEffect();
      @extend .burger-button;
      background: $white;
    }
  }

  &-simple {
    span {
      @include clickEffect();
      @extend .burger-button;
      background: $primary;
    }
  }

  &-open {
    span:nth-child(1) {
      @include position($top: 0, $left: 0);
      background: $primary;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      width: 0;
      opacity: 0;
    }

    span:nth-child(3) {
      @include position($bottom: 0, $left: 0);
      background: $primary;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

.burger-button {
  @include size($width: 100%, $height: 3px);
  @include position($position: absolute, $left: 0);
  @include clickEffect();

  content: '';
  display: block;
  border-radius: 9px;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;

  &:nth-child(1) {
    top: 0;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &:nth-child(2) {
    top: calc(50% - 1.5px);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &:nth-child(3) {
    bottom: 0;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
}
