@import 'src/assets/scss/_variables.scss';

.partner-slider-section {
  padding: 120px 0;
  text-align: center;
  overflow: hidden;

  h2 {
    margin-bottom: 28px;
  }

  p {
    max-width: 940px;
    margin: 0 auto 36px;
  }

  button {
    margin-top: 28px;
  }

  &-container {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    position: relative;

    a {
      height: 137px;
      overflow: hidden;
      position: relative;
      display: block;
    }

    img {
      max-width: 100%;
    }
  }

  &-item {
    background-color: $background-secondary;
    padding: 16px;
    margin: 0px 16px;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-left-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 100%;
    z-index: 9;
    background: linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 90%, rgba(250,250,250,0) 100%);

    @media screen and (max-width: 1600px) {
      display: none;
    }
  }

  &-right-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    height: 100%;
    z-index: 9;
    background: linear-gradient(90deg, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 10%, rgba(250,250,250,1) 100%);

    @media screen and (max-width: 1600px) {
      display: none;
    }
  }
}