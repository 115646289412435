@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);

  &-title {
    @include displayFlex($display: flex, $direction: row);
    @extend .title;
    margin: 40px 0 80px;

  }

  &--live {
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  &-title-top {
    @include displayFlex($display: flex, $direction: row);
    @extend .title;
    margin: 80px 0 30px;
  }
}

.actions-wrapper {
  @include displayFlex($display: flex, $direction: row);
  margin-bottom: 150px;

  @include media-breakpoint-down(md) {
    margin-bottom: 100px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 50px;
  }
}

.tabs-wrapper {
  padding-right: 80px;
  width: 70%;

  @include media-breakpoint-down(lg) {
    padding-right: 40px;
  }

  @include media-breakpoint-down(md) {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

.partner-wrapper {
  width: 30%;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.accordion-wrapper {
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.title {
  span {
    @include font($size: 12px, $color: $white, $transform: uppercase, $family: $font-fibon-bold, $line-height: 12px);
    @include size($min-width: 62px, $height: 32px);
    cursor: default;
    padding: 12px 15px;
    border-radius: 2px;
    background: $secondary;
    margin-right: 30px;
  }

  h4 {
    color: $black;
    flex-grow: 1;
  }

  @include media-breakpoint-down(md) {
    margin: 40px 0 40px;
  }

  @include media-breakpoint-down(sm) {
    margin: 30px 0 30px;
  }
}
