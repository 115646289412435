//  ========================================================================
//  General
//  ======================================================================== 
@mixin content-center($center: 'all') {
  position: absolute;

  @if $center == 'all' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if $center == 'horizontal' {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  } @else if $center == 'vertical' {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin hover-state() {
  &:active, &:hover {
    outline: none;
    box-shadow: none;
    @content;
  }
}

@mixin focus-state() {
  &.focus, &:focus {
    outline: none;
    box-shadow: none;
    @content;
  }
}

@mixin img-responsive() {
  display: block;
  max-width: 100%;
  height: auto;
}

@mixin clear-btn() {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  &:hover, &:active, &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

@mixin hide-input () {
  position  : absolute;
  opacity   : 0;
  left      : -9999px;
  visibility: hidden;
}

@mixin image-aspect($aspect-ratio-width: null, $aspect-ratio-height: null, $container-width: null) {
  @include aspect-ratio($aspect-ratio-width, $aspect-ratio-height);
  margin: 0;
  position: relative;
  width: $container-width;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

//  ========================================================================
//  Custom
//  ========================================================================
@mixin aspect-ratio($arglist... /*$ratio or $width, $height*/
) {
  $map: keywords($arglist);

  $width: map-get($map, width) or nth-value($arglist, 1);
  $height: map-get($map, height) or nth-value($arglist, 2);
  $ratio: map-get($map, ratio) or if($width and $height, $width/$height, nth-value($arglist, 1)) or 1;
  $padding: 1/$ratio * 100%;

  &::after, &::before {
    content: '';
    display: table;
    clear: both;
    width: 1px;
    margin-left: -1px;
  }

  &::before {
    float: left;
    padding-bottom: $padding;
  }
}

// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-value($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}

@mixin nf-checkboxes-radios($container, $inner-container, $border, $radius, $pad-left:null, $radio-pos:null, $label-Hcolor: $primary ) {
  [type='checkbox'], [type='radio'] {
    &:checked, &:not(:checked) {
      @include hide-input();

      + label {
        position: relative;
        display: flex;
        height: $container;
        height: auto;
        padding-left: $pad-left;
        cursor: pointer;
        @include hover-state() {
          &::before {
            border-color: $primary;
          }

          p {
            color: $label-Hcolor;
          }
        }

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: $container;
          height: $container;
          background: transparent;
          border: $border solid $white;
          border-radius: $radius;
          transition: all .2s ease-in-out;
          @include hover-state() {
          }
        }

        &::after {
          position: absolute;
          content: '';
          top: $radio-pos;
          left: $radio-pos;
          width: $inner-container;
          height: $inner-container;
          background: $primary;
          border-radius: $radius;
          transition: all .2s ease-in-out;
        }

        .icon {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: $container;
          height: $container;
          font-size: 10px;
          color: transparent;
          z-index: 1;
          transition: all .2s ease-in-out;
        }

        p {
          font-size: 13px;
          color: $white;
          margin: 0;
          transition: all .2s ease-in-out;
        }
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked {
      + label {
        &::before {
          border: $border solid $primary;
        }

        &::after {
          opacity: 1;
          transform: scale(1);
        }

        .icon {
          color: $black;
        }
      }
    }
  }
}
