@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $wrap: wrap, $direction: column, $grow: 1);
  @include size($width: 100%, $min-height: 100%, $height: 100%);
  background: $background;
  color: $black;
  padding: 0 300px;
  text-align: left;

  @include media-breakpoint-down(xl) {
    padding: 0 250px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 200px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 100px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 50px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 25px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 18px;
  }
}

.course {
  &-title {
    @include font($transform: uppercase, $line-height: 40px);
    margin-top: 20px;
  }

  &-short-description {
    margin-top: 40px;
  }
}

.close-wrapper {
  @include clickEffect();
  margin-top: 80px;
}
