@import '../variables';
@import '../mixins';

.wrapper {
  background: $white;
  @include displayFlex($display: flex, $justify-content: center, $wrap: wrap, $direction: column);
  @include size($width: 300px);
  @include position($position: absolute, $top: 82px, $right: 270px);
  box-shadow: 0 2px 8px #00000033;
  border-radius: 4px;
  opacity: 1;
  z-index: 1000;
  right: 0;

  @include media-breakpoint-down(sm) {
    @include position($top: 68px, $right: 120px);
    @include size($width: 250px);
  }

  @include media-breakpoint-down(xs) {
    right: 90px;
    @include size($width: 220px);
  }

  p {
    font-size: 18px;
    cursor: pointer;
    &.email-content {
      font-size: 16px;
    }
  }

  &-content {
    padding: 30px 15px 30px 30px;
    border-top: 2px solid $grey;
    border-bottom: 2px solid $grey;
    color: $black;

    &:last-child {
      margin-bottom: 0px;
    }

    p {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &-footer {
    padding: 30px 0 30px 30px;
    color: $secondary;
  }
}

.avatar {
  padding: 30px 0;
  margin: 0 15px;
  position: relative;
  @include displayFlex($display: flex, $direction: row);

  p {
    color: $black;
  }

  p:first-child {
    @include font($family: $font-fibon-bold, $font-weight: 700);
  }

  &-image {
    @include media-breakpoint-down(sm) {
      margin-right: 18px;
    }
    @include size($width: 63px, $height: 63px);
    min-width: 63px;
    background: $primary;
    border-radius: 50%;
    @include displayFlex($display: flex, $align-items: center, $justify-content: center);
    color: $white;
    margin-right: 26px;

    p {
      @include font($size: 30px, $transform: uppercase);
      color: $white;
      padding-top: 5px;
    }

    img {
      @include size($width: 100%, $height: 100%);
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.email-content {
  font-size: 16px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  max-width: 180px;

  @include media-breakpoint-down(sm) {
    max-width: 130px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 110px;
  }
}
