@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);
}

.actions-wrapper {
  @include displayFlex($display: flex, $direction: row);
  margin-bottom: 100px;

  @include media-breakpoint-down(md) {
    margin-bottom: 0px;
  }

}

.partner-wrapper {
  width: 100%;
  @include media-breakpoint-down(md) {
    display: none;
  }
}


.accordion-wrapper {
  margin-bottom: 10px;  
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}
