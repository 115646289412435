@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: space-evenly, $align-items: center, $direction: column);
  height: 100%;
  overflow: hidden;
  padding: 50px;

  button {
    width: 100%;
    margin: 5px 5px 5px 5px;
  }

  p {
    text-align: center;
  }

  div {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    @include displayFlex($display: flex, $direction: row);
    width: 100%;
  }
}

.title {
  @include font($size: 32px, $family: $font-fibon-bold);
}

.description {
  margin: 20px 0 30px 0;
}
