@import '../variables';
@import '../extends';
@import '../mixins';

.iframe-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    @include position($position: absolute, $top: 0, $left: 0, $bottom: 0, $right: 0);
    @include size($width: 100%, $height: 100%);
  }
}

.image-wrapper {
  @include image-aspect(16, 9, 100%);
}