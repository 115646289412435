@import '../../variables';
@import '../../extends';
@import '../../mixins';

.coaching-section {
  margin-top: 100px;

  @include media-breakpoint-down(md) {
    margin-top: 50px;
  }
  
  h4 {
    margin: 0 0 20px 0;
    font-size: 24px;
  }
}
