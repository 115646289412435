$font-icon-path: "../../fonts/font-icons/";

@font-face {
  font-family: 'fontello';
  src: url('#{$font-icon-path}fontello.eot?91377304');
  src: url('#{$font-icon-path}fontello.eot?91377304#iefix') format('embedded-opentype'),
  url('#{$font-icon-path}fontello.woff2?91377304') format('woff2'),
  url('#{$font-icon-path}fontello.woff?91377304') format('woff'),
  url('#{$font-icon-path}fontello.ttf?91377304') format('truetype'),
  url('#{$font-icon-path}fontello.svg?91377304#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?91377304#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: auto;
  margin-right: 0;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-right-open-big:before { content: '\e800'; } /* '' */
.icon-down-open-big:before { content: '\e800'; transform: rotate(90deg);} /* '' */
.icon-up-open-big:before { content: '\e800'; transform: rotate(-90deg);} /* '' */
.icon-arrow-go-to:before { content: '\e82a'; } /* '' */
.icon-close:before { content: '\e82f'; } /* '' */