@import '../variables';
@import '../mixins';

.title {
  margin: 30px 24px;

  @include media-breakpoint-down(sm) {
    margin: 24px 0;
  }

  text-transform: uppercase;
}

.secondary-title {
  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  margin-top: 2rem;
}

.course-card {
  @include displayFlex($display: flex !important, $direction: column);

  @include media-breakpoint-down(sm) {
    margin: 20px 0 0 0;
  }

  @include media-breakpoint-down(xm) {
    margin: 0;
  }

  height: inherit;
  margin-top: 24px;
  margin-right: 24px;
  width: 320px;
  padding: 0;
  border: 1px solid $border-grey-secondary;
  border-radius: 20px;
  background-color: $white;

  &-content {
    @include displayFlex($display: flex, $direction: column, $justify-content: space-between, $grow: 1);
    padding: 24px;
    border-top: 1px solid $grey;

    h3,
    p {
      color: $black;
    }

    h3 {
      margin-top: 10px;
      cursor: pointer;
      font-size: 24px;
    }

    p {
      flex-grow: 1;
      margin-top: 10px;
      font-size: 18px;
    }
  }

  &-logo {
    figure {
      @include image-aspect(16, 10, 100%);

      img {
        border-radius: 20px 20px 0 0;
      }

      span {
        @include position($position: absolute, $top: 0, $left: 0);
        @include font($size: 16px, $family: $font-fibon-bold, $color: $white);
        background: $secondary;
        border-radius: 20px 0 0;
        padding: 10px;
        z-index: 10;
      }
    }
  }
}

.wrapper-progress {
  &-line {
    margin-top: 52px;
    height: 4px;
    background: $grey;

    div {
      background: $primary;
      height: 100%;
    }
  }
}

.filesButton {
  margin-bottom: 1rem;
}

.kalendarButton {
  margin-bottom: 1rem;
}

.slider {
  @include displayFlex($display: flex, $wrap: wrap);

  width: 100%;
}

.slider-wrapper {
  margin: 0;
}

.mainButton {
  margin-top: 25px;
}

.last-viewed-section {
  margin-top: 40px;
}