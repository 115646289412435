@import '../variables';
@import '../mixins';

.wrapper {
  @include media-breakpoint-down(sm) {
    padding: 10px;
    width: 100% !important;
  }

  @include displayFlex($display: flex, $justify-content: center, $direction: column, $align-items: center);

  text-align: center;
  padding: 0px;
  height: 100%;
  overflow: hidden;
}

.margin {
  margin-bottom: 20px;
}

.row {
  width: 100%;
  padding: 0px 50px 0px 50px;
}

.button {
  width: 100%;
  &-container {
    padding: 10px 5px 0px 5px;
  }
}
