@import '../mixins';

.wrapper {
  @include media-breakpoint-down(sm) {
    margin: 50px 0px 50px;
  }

  margin: 100px 0px 100px;

  font: $font-secondary;
}
