@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: center, $direction: row);
  max-width: 500px;
  height: 100%;
  cursor: pointer;

  &-vertical {
    @include displayFlex($display: flex, $justify-content: center, $direction: column);

    figure {
      @include image-aspect(9, 6, 100%);
      max-width: 500px;

      img {
        border-radius: 20px 20px 0 0;
      }

      span {
        @include position($position: absolute, $top: 0, $left: 0);
        @include font($size: 16px, $family: $font-fibon-bold, $color: $white);
        background: $primary;
        border-radius: 20px 0 20px;
        padding: 6px 30px 6px 30px;;
        z-index: 8;
      }
    }
  }

  &-horizontal {
    max-width: unset;
    @include size($width: 100%, $height: 100%);

    @include media-breakpoint-down(sm) {
      @include displayFlex($display: flex, $justify-content: center, $direction: column);
    }

    figure {
      @include image-aspect(9, 6, 50%);

      @include media-breakpoint-down(sm) {
        @include image-aspect(9, 6, 100%);
      }

      img {
        border-radius: 20px 0 0 20px;

        @include media-breakpoint-down(sm) {
          border-radius: 20px 20px 0 0;
        }
      }

      span {
        @include position($position: absolute, $top: 0, $left: 0);
        @include font($size: 16px, $family: $font-fibon-bold, $color: $white);
        background: $secondary;
        border-radius: 20px 0 0;
        padding: 15px;
        z-index: 10;
      }
    }
  }

  &-live {
    max-width: unset;
    @include size($width: 100%, $height: 100%);

    @include media-breakpoint-down(sm) {
      @include displayFlex($display: flex, $justify-content: center, $direction: column);
    }

    figure {
      @include image-aspect(9, 6, 50%);

      @include media-breakpoint-down(sm) {
        @include image-aspect(9, 6, 100%);
      }

      img {
        border-radius: 0;
      }
    }
  }
}

.details {
  &-wrapper {
    @include displayFlex($display: flex, $justify-content: space-between, $direction: column);
    border: 1px solid $border-grey-secondary;
    background-color: $white;
    padding: 50px;
    height: 100%;

    &-text {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      &-live {
        text-transform: uppercase;
        color: $secondary;
      }

      p {
        font-weight: 700;
      }
    }

    h3 {
      margin-bottom: 20px;
    }

    &-live {
      @include size($width: 50%, $height: auto);
      border-radius: 0;
      padding: 70px 90px;

      @include media-breakpoint-down(lg) {
        padding: 60px;
      }

      @include media-breakpoint-down(md) {
        padding: 50px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 30px;
      }
    }

    &-vertical {
      @include size($max-width: 500px, $width: 100%);
      border-radius: 0 0 20px 20px;
      border-top: none;
      padding: 50px 30px 30px;
    }

    &-horizontal {
      @include size($width: 50%, $height: auto);
      border-radius: 0 20px 20px 0;
      border-left: none;
      padding: 50px;

      @include media-breakpoint-down(sm) {
        border-radius: 0 0 20px 20px;
        width: 100%;
        padding: 30px;
      }
    }
  }

  &-button {
    margin-top: 30px;

    &--live {
      margin-bottom: 25px;

      span {
        @include font(
          $size: 12px,
          $color: $white,
          $transform: uppercase,
          $family: $font-fibon-bold,
          $line-height: 12px
        );
        cursor: default;
        height: 32px;
        padding: 12px 15px;
        border-radius: 2px;
        background: $secondary;
      }
    }
  }
}

.marginTop2 {
  margin-top: 2rem;
  color: red;
}

.kalendarButton {
  margin-left: 0.5rem;

  @include media-breakpoint-down(md) {
    margin-left: 0.3rem;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

.secondary-label {
  background: $secondary !important;
}

.calendar-module {
  display: flex;
  flex-direction: column;
}