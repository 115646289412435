@import '../extends';
@import '../variables';
@import '../mixins';

.footer {
  &-wrapper {
    @include displayFlex($display: flex, $justify-content: space-between, $align-items: center);

    color: $white;
    min-height: 54px;
    background: $primary-dark;
    padding: 0;

    @include media-breakpoint-down(sm) {
      @include displayFlex($direction: column);
    }
  }

  &-copyright {
    @include font($size: 16px, $font-weight: 300);
    opacity: .7;

    @include media-breakpoint-down(sm) {
      order: 3;
      text-align: center;
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &-links {
    flex-grow: 1;
    margin-left: 70px;

    &--item {
      @include clickEffect();
      @include font($size: 16px, $color: $white, $font-weight: 300);

      @include media-breakpoint-up(md) {
        &:not(:last-child) {
          margin-right: 44px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      order: 2;
      margin-left: 0;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &--item {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }

  &-socials {
    display: flex;

    @include media-breakpoint-down(sm) {
      order: 1;
      margin: 16px 0;
    }

    &--item {
      display: inline-flex;
      margin-left: 20px;
    }
  }

  &-details {
    padding: 40px 0px;
    background-color: $primary-darker;
    color: $white;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    p {
      font-family: $font-fibon-regular;
      margin-bottom: 16px;
    }

    a {
      font-weight: 200;
      color: $white;
      display: block;
      margin-bottom: 16px;
      line-height: 16px;
    }

    &-logocontainer {
      align-self: center;
    }

    &-logo {
      margin-top: 20px;
    }

    &-column{
      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
      }
    }
  }
}
