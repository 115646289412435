@import '../variables';

@mixin size($width: null, $height: null, $min-width: null, $min-height: null, $max-width: null, $max-height: null) {
  width: $width;
  height: $height;
  min-width: $min-width;
  min-height: $min-height;
  max-width: $max-width;
  max-height: $max-height;
}

@mixin displayFlex(
  $display: null,
  $justify-content: null,
  $align-items: null,
  $direction: null,
  $wrap: null,
  $grow: null
) {
  display: $display;
  flex-direction: $direction;
  flex-wrap: $wrap;
  flex-grow: $grow;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin font(
  $size: null,
  $weight: null,
  $transform: null,
  $color: null,
  $line-height: null,
  $text-align: null,
  $text-overflow: null,
  $family: null,
  $font-weight: null,
  $word-spacing: null,
  $word-break: null,
  $text-transform: null
) {
  color: $color;
  line-height: $line-height;
  text-align: $text-align;
  text-overflow: $text-overflow;
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  word-spacing: $word-spacing;
  text-transform: $transform;
  font-weight: $font-weight;
  word-break: $word-break;
  text-transform: $text-transform;
}

@mixin placeholderColor($color: $primary, $opacity: null) {
  ::-webkit-input-placeholder {
    /* Edge */
    color: $color;
    opacity: $opacity;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
    opacity: $opacity;
  }

  ::placeholder {
    color: $color;
    opacity: $opacity;
  }
}

@mixin clearOutline() {
  &:active,
  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

@mixin clickEffect() {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

@mixin blur($backdrop-filter: null, $background-color: null) {
  backdrop-filter: blur($backdrop-filter);
  background-color: $background-color;
}

@mixin backgroud-image($background-image: null, $repeat: null, $position: null) {
  background: url($background-image) $repeat $position;
}

.partners-page-container {

  .text-center {
    text-align: center !important;
  }

  .mb160{
    margin-bottom: 160px;
  }
  .mb140 {
    margin-bottom: 140px;
  }
  .mb110 {
    margin-bottom: 110px;
  }
  .mb100 {
    margin-bottom: 100px;
  }
  .mb70 {
    margin-bottom: 70px;
  }
  .mb50 {
    margin-bottom: 50px;
  }
  .mb20{
    margin-bottom: 20px;
  }
  .mt180 {
    margin-top: 180px;
  }
  .mt170 {
    margin-top: 170px;
  }
  .mt160 {
    margin-top: 160px;
  }
  .mt150 {
    margin-top: 150px;
  }
  .mt140 {
    margin-top: 140px;
  }
  .mt130 {
    margin-top: 130px;
  }
  .mt120 {
    margin-top: 120px;
  }
  .mt110 {
    margin-top: 110px;
  }
  .mt100 {
    margin-top: 100px;
  }
  .mt80 {
    margin-top: 80px;
  }
  .mt70 {
    margin-top: 70px;
  }
  .mt60 {
    margin-top: 60px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .mt30 {
    margin-top: 30px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt15 {
    margin-top: 15px;
  }
  h3 {
    font-size: 24px;
  }
  p {
    a {
      @include font($family: $font-fibon-bold, $color: $primary);
      text-decoration: underline;
    }
    strong {
      @include font($family: $font-fibon-bold, $color: $primary);
    }
  }
  .left-aligned {
    text-align: left;
  }
  .fibon-font {
    @include font($family: $font-fibon-regular, $color: $primary);
  }
  .image-caption-text {
    @include font($family: $font-fibon-regular, $color: $primary);
    font-size: 18px;
  }
  .carouse-section {
    h4 {
      text-align: left;
    }
    p {
      text-align: left;
    }
    article:first-child {
      margin-top: 0px;
    }
  }
  .slick-slider {
    width: 100%;
    .slick-slide {
      padding-left: 0px;
    }
  }
  .full-grey-background {
    background: #3047850D;
  }
  .full-grey-background-old {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: #3047850D;
  }
  .image-container {
    max-width: 422px;
    max-height: 422px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .download-title {
    @include font($family: $font-fibon-bold, $color: $primary);
    font-size: 20px;
  }

  .download-description {
    color: $primary;
    text-align: left;
    font-size: 18px;
    width: 100%;
  }

  .download-links {
    max-width: 1045px;
    width: 100%;
    margin: 0 auto;
  }

  .small-container {
    max-width: 746px;
    width: 100%;
    margin: 0 auto;
  }

  .no-padding {
    padding: 0;
  }

  .no-margin {
    margin: 0;
  }

  .cnclogowhiteflipped {
    transform: scaleX(-1);
    filter: brightness(100);
    position: absolute;
    left: -7%;
    top: 45px;
    max-height: calc(100% - 90px);
    max-width: 100%;
  }

  .spacer {
    width: 10px;
    &.main-download {
      height: 528px;
    }
  }

  .company-logo {
    max-width: 273px;
    max-height: 200px;
    @include media-breakpoint-up(xl) {
      max-width: 550px;
    }
    // padding: 0 1%;
  }
  .company-media {
    max-width: 746px;
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .interested-in-title {
    line-height: 1.3;
  }
  .company-contact {
    padding: 0 90px;
    @include media-breakpoint-down(xl) {
      padding: 0 62px;
    }
  }
  .company-media-player {
    iframe, video, embed{
    max-width: 746px;
    width: 100%;
    aspect-ratio: 16 / 9;
    }
  }
}

.company-media-player {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe, video, embed{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}