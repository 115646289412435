@import '../variables';
@import '../extends';
@import '../mixins';

.header {
  &-wrapper {
    @include displayFlex($display: flex, $justify-content: space-between, $align-items: center);
    font-family: $font-fibon-bold;
    z-index: 100;
    background: $white;
    border-radius: 0 0 2px 2px;

    position: sticky;
    top: 0px;
    height: 100px;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;

    @include media-breakpoint-down(md) {
      height: 90px;
      // position: fixed;
      width: 100%;
      border-bottom: $border-grey solid 1px;
    }
  }

  &-down {
    top: -100px;
  }

  &-logo {
    cursor: pointer;
    flex-grow: 1;
    display: flex;
    @include media-breakpoint-down(sm) {
      height: 50px;
    }

    &--large {
      @include size($width: 196px, $height: 66px);
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--small {
      @include size($width: auto, $height: 50px);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &-links {
    @include displayFlex($display: flex, $align-items: center);
    text-transform: uppercase;
    height: 100%;
    margin: 0 30px 0 10px;

    &--item {
      @include clickEffect();
    }

    &--container {
      @include displayFlex($display: flex, $align-items: center, $justify-content: space-between);
      padding: 19px 0 !important;
      font-family: $font-fibon-bold;

      span {
        padding-left: 8px !important;
        font-weight: bold !important;
      }

      i {
        padding-right: 12px !important;
      }
    }

    &--element {
      @include displayFlex($display: flex, $align-items: center);
      border-top: 1px solid #E1E5EB;
      padding: 19px 0 !important;

      span {
        padding-left: 8px;
      }

      i {
        padding-right: 12px;
      }
    }

    &--logout {
      color: $secondary;
    }

    &--indent {
      padding-left: 8px !important;
    }

    &--partner-list {
      display: none;
      box-shadow: 0px 0px 15px #00000033;
      left: -30;
      position: fixed;
      z-index: 1;
      background: white;
      padding: 30px;
      min-width: 267px;
      @include font($family: $font-main, $size: 18px, $line-height: 35px);

      &-item {
        @include clickEffect();

        color: black;
        font-size: 18px;
        text-transform: initial;
      }
    }

    &-popover {
      // margin-top: 36px;
      border-radius: 16px;
      background-color: $background;
      box-shadow: 0px 0px 16px #2A3E7A4D;
      display: grid;
      grid-template-columns: auto auto auto;
      padding: 46px;

      &-item {
        display: flex;
        width: 258px;
        cursor: pointer;
        border-radius: 8px;
        padding: 14px;

        &:hover {
          background-color: $background-secondary;
        }

        &--figure {
          width: 50px;

          @include media-breakpoint-down(md) {
            width: 20px;
            margin-left: 8px;
            margin-right: 12px;
          }
        }

        &-details {
          flex-grow: 1;
          margin-left: 16px;

          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
          }

          &--header {
            font-family: $font-fibon-bold;
            text-transform: uppercase;
          }
        }
      }
    }

    &--active {
      position: relative;

      @include media-breakpoint-down(lg) {
        border-bottom: 2px solid $primary;
      }

      &:after {
        @include position($position: absolute, $left: 0, $bottom: -36px);
        @include size($width: 100%, $height: 2px);
        content: '';
        background: $primary;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    & > span {
      &:not(:last-child) {
        margin-right: 48px;
      }
    }

    i {
      margin-left: 16px
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    &-collapse {
      list-style: none;
      font-size: 18px;
      text-transform: none;

      > li {
        text-transform: capitalize;
      }
    }
  }

  &-buttons {
    display: flex;

    button {
      @include font($size: 13px, $family: $font-fibon-bold);
      @include displayFlex($display: flex, $align-items: center, $justify-content: center);
      height: 44px;
      text-align: center;
      line-height: 40px;
      padding: 0 24px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      @include media-breakpoint-down(sm) {
        height: 42px;
        line-height: 38px;
      }

      @include media-breakpoint-down(xm) {
        height: 34px;
        line-height: 30px;
      }
    }


    @include media-breakpoint-down(md) {

      button {
        display: none;
      }
    }
  }
}

.shadower {
  box-shadow: 0px 0px 6px #00000033;
}

.profile-initials {
  @include displayFlex($display: flex, $align-items: center, $justify-content: center);
  @include size($width: 38px, $height: 38px, $min-width: 38px, $min-height: 38px);
  @include clickEffect();
  position: relative;
  background: $primary;
  border-radius: 100%;
  cursor: pointer;
  margin-left: 10px;

  p {
      @include font($transform: uppercase, $color: $white, $weight: 700, $size: 16px);
  }

  // &:before {
  //   // TODO - implement with notifications
  //   //@include size($width: 11px, $height: 11px);
  //   //@include position($position: absolute, $top: -5px, $right: -1px);
  //   //content: '';
  //   //background: $secondary;
  //   //border-radius: 100%;
  //   //border: 2.5px solid $white;
  //   //
  //   //@include media-breakpoint-down(xm) {
  //   //  display: none;
  //   //}
  // }

  img {
    @include size($width: 100%, $height: 100%);
    border-radius: 100%;
    object-fit: cover;
  }
}

.profile-initials-burger {
    @include displayFlex($display: flex, $align-items: center, $justify-content: center);
  @include size($width: 38px, $height: 38px, $min-width: 38px, $min-height: 38px);
  position: relative;
  background: $primary !important;
  border-radius: 100%;
  cursor: pointer;
  margin-left: 10px;

  p {
    @include font($transform: uppercase, $color: $white, $weight: 700, $size: 16px);
    background: $primary !important;
  }

  // &:before {
  //   // TODO - implement with notifications
  //   //@include size($width: 11px, $height: 11px);
  //   //@include position($position: absolute, $top: -5px, $right: -1px);
  //   //content: '';
  //   //background: $secondary;
  //   //border-radius: 100%;
  //   //border: 2.5px solid $white;
  //   //
  //   //@include media-breakpoint-down(xm) {
  //   //  display: none;
  //   //}
  // }

  img {
    @include size($width: 100%, $height: 100%);
    border-radius: 100%;
    object-fit: cover;
  }
}
