@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);

  ol, ul {
    padding-left: 30px;
  }
}

.tabs {
  @include displayFlex($display: flex, $grow: 1, $direction: row);
  @include font($size: 20px, $family: $font-fibon-bold, $color: $text-tertiary-pale);
  border-bottom: 1px solid $border-grey;
  margin-bottom: 20px;

  @include media-breakpoint-down(xm) {
    font-size: 16px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }

  &-active {
    position: relative;
    color: $primary;
    padding-bottom: 20px;

    &:after {
      @include position($position: absolute, $left: 0, $bottom: -1px);
      @include size($width: 100%, $height: 2px);
      content: '';
      background: $primary;
    }
  }

  span {
    @include clickEffect();
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 40px;

      @include media-breakpoint-down(lg) {
        margin-right: 20px;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 20px;
      }

      @include media-breakpoint-down(xs) {
        margin-right: 10px;
      }
    }
  }
}
