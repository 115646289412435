@import '../variables';
@import '../mixins';

.title {
  &-wrapper {
    @include displayFlex($display: flex, $direction: column);
    margin: 20px 0;
  }

  &-lesson {
    @include font($size: 14px, $color: $text-tertiary-pale);
    margin-bottom: 10px;
  }

  &-course {
    @include font($size: 32px, $family: $font-fibon-bold, $transform: uppercase);
    margin-bottom: 20px;
  }
}

.quiz {
  &-wrapper {
    @include displayFlex($display: flex, $direction: column);
    padding-right: 80px;

    @include media-breakpoint-down(md) {
      padding: 0 120px 40px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 60px 40px;
    }

    @include media-breakpoint-down(xm) {
      padding: 0 30px 40px;
    }

    @include media-breakpoint-down(xs) {
      padding: 0 20px 40px;
    }
  }

  &-button {
    padding-top: 30px;
  }
}

.video {
  &-section {
    @include displayFlex($display: flex, $wrap: wrap);

    &--short-description {
      @include font($size: 16px, $color: $text-tertiary-pale);
      margin-top: 12px;

      @include media-breakpoint-down(md) {
        padding: 0 120px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 60px;
      }

      @include media-breakpoint-down(xm) {
        padding: 0 30px;
      }

      @include media-breakpoint-down(xs) {
        padding: 0 20px;
      }
    }
  }

  &-title {
    margin-bottom: 20px;
    @include media-breakpoint-down(md) {
      padding-left: 120px;
    }
  
    @include media-breakpoint-down(sm) {
      padding-left: 60px;
    }
  
    @include media-breakpoint-down(xm) {
      padding-left: 30px;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 20px;
    }
  }

  &-wrapper {

    h4 {
      @include media-breakpoint-down(lg) {
        font-size: 20px;
      }
    }
    @include displayFlex($display: flex, $direction: column);
    width: 70%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &-tabs {
    margin-top: 40px;

    @include media-breakpoint-down(md) {
      padding: 0 40px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 30px;
    }

    @include media-breakpoint-down(xm) {
      padding: 0 20px;
    }
  }

  &-details {
    width: 30%;
    padding-left: 20px;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0 20px;
    }
  }
}
