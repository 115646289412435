@import '../mixins';
@import '../variables';

.wrapper {
  margin-bottom: 50px;
}

.title {
  margin: 80px 0px 30px;
}

.inputField {
  color: $primary;
  background-color: $grey;
  border: 0px;
  width: 100%;
  margin: 10px 0px 10px;
  border-radius: 4px;
  &::placeholder {
    opacity: 0.4;
  }
}

.inputField::placeholder {
  opacity: 0.4;
}

.plz-ort-container {
  @include displayFlex($display: flex, $direction: row);

  width: 100%;
}

.plz-container {
  width: 35%;
  margin-right: 25px;
}

.ort-container {
  width: 65%;
}

.anchor {
  color: $secondary;
  text-decoration: underline;
}
.more-input {
  margin: 10px 0px 30px;
}

.required-info {
  @include displayFlex($display: flex, $align-items: center);
  color: $secondary;
  margin-top: 15px;
  p {
    color: $black;
    font-size: 16px;
  }
}

.error-text {
  font-size: 12px;
  min-height: 20px;
  word-break: initial;
}
