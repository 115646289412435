@import '../variables';
@import '../mixins';

.line {
  margin-bottom: 30px;
  color: $primary;
}

.title {
  &-container {
    @include displayFlex($display: flex, $direction: row, $align-items: flex-end);
    margin-bottom: 30px;
  }
}

.image {
  &-container {
    @include size($width: 40px,$height:40px);
    @include displayFlex($display: flex, $align-items:center, $justify-content:center);
    cursor: pointer;
    border-radius: 50%;
    background: $primary;
    margin-left: 30px;
  }
}
.wrapper {
  h4 {
    @include media-breakpoint-down (md) {
      font-size: 18px;
    }
    font-size: 20px;
  }
  p {
    @include media-breakpoint-down (md) {
      font-size: 18px;
    }
    font-size: 20px;
  }
}
