@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);
  margin-top: 80px;

  h2 {
    text-transform: uppercase;
  }

  p {
    margin-top: 20px;
  }

  span {
    margin-top: 40px;
  }
}

.link {
  label {
    @include clickEffect();
    text-decoration: underline;
  }

  &:before, &:after {
    content: ' ';
  }
}

.schulungTitle {
  font-weight: bold;
}
