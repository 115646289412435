@import '../variables';
@import '../mixins';

.wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  img {
    max-width: inherit;
    object-fit: contain;
  }
}

.react-player {
  @include position($position: absolute, $top: 0, $left: 0);
}