@import '../variables';
@import '../mixins';

.wrapper {
  h4 {
    margin: 40px 0 !important;
  }
}

.form {
  &-wrapper {
    @include displayFlex($display: flex, $direction: column);
  }

  &-action {
    @include displayFlex($display: flex, $grow: 1);

    input {
      @include font($size: 18px, $color: $primary-70);
      flex-grow: 1;
      padding: 0 16px;
      border-radius: 4px 0 0 4px;
      border: 1px solid $primary;
      background: $white;
    }

    button {
      border-radius: 0 4px 4px 0;
    }
  }

  &-button {
    padding: 0 16px;

    i {
      font-size: 18px;
      display: block;
      transform: rotate(45deg);
    }

    @include media-breakpoint-down(sm) {
      padding: 0 12px;

      i {
        font-size: 14px;
      }
    }
  }

  &-error {
    @include font($size: 12px, $color: $secondary);
    width: 100%;
  }
}

.note-item {
  @include displayFlex($display: flex, $direction: row);
  margin-top: 40px;

  &:not(:last-child) {
    border-bottom: 1px solid $border-grey;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  button {
    @include font($size: 12px, $line-height: 12px, $weight: 500);
    padding: 0 16px;
    height: 32px;
    border-radius: 0;
  }

  span {
    @include displayFlex($display: flex, $justify-content: center, $align-items: center);
    @include font($size: 20px, $color: $black, $weight: 500);
  }

  &-content {
    margin-left: 24px;
  }
}
