@import '../variables';
@import '../mixins';

.button {
  @include displayFlex($display: flex, $align-items: center);

  @include media-breakpoint-down(sm) {
    margin: 30px 0px 20px;
  }

  color: $secondary;
  font-size: 16px;
  cursor: pointer;
  margin: 60px 0px 30px;

  p {
    font-size: 16px;
    margin-left: 13px;
  }
}
