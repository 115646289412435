@import '../variables';
@import '../extends';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $wrap: wrap);

  h4 {
    margin: 0 0 20px 0;
    font-size: 24px;
  }
}
