@import '../variables';
@import '../mixins';

.wrapper {
  color: $black;

  h4 {
    margin: 40px 0 !important;
  }
}

.item {
  &-wrapper {
    @include displayFlex($display: flex, $align-items: center, $justify-content: space-between);

    @media screen and (max-width: 800px) {
      margin-left: 0px;
      margin-right: 0px;
    }

    margin-left: 30px;
    margin-right: 30px;

    &:not(:last-child) {
      border-bottom: 1px solid $border-grey;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &-no-margin {
      margin: 0;
    }
  }

  &-content {
    @include displayFlex($display: flex, $direction: column);
    margin-right: 12px;

    &--title {
      @include displayFlex($display: flex, $align-items: center);
      @include font($family: $font-fibon-bold, $weight: 700);

      i {
        font-size: 12px;
        margin-right: 10px;
      }
    }

    span {
      font-size: 20px;
    }
  }
}

.download-button {
  height: 50px;
  width: 50px;
  @include displayFlex($display: flex, $align-items: center, $justify-content: center);
}


