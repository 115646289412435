@import '../mixins';
@import '../variables';

.card {
  @include displayFlex($display: flex, $align-items: center, $justify-content: space-between);
  @include size($width: 100%, $min-height: 80px);
  border-radius: 4px;
  background-color: $grey;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid transparent;

  &-selected {
    border: 1px solid $primary;
  }

  &-unselected {
    opacity: 0.5;
  }

  &-correct {
    border: 1px solid $background-green;
    color: $background-green;
    background-color: $background-light-green;
  }

  &-incorrect {
    border: 1px solid $secondary;
    color: $secondary;
    background-color: $background-light-secondary;
  }
}

.item {
  @include size($width: 15px, $height: 15px);
  content: ' ';
  border: 1px solid $primary;
  border-radius: 50%;
  position: relative;

  &-selected {
    &:after {
      @include size($width: 9px, $height: 9px);
      @include position($position: absolute, $top: 2px, $left: 2px);
      content: ' ';
      background-color: $primary;
      border-radius: 50%;
    }
  }
  &-correct {
    border: 1px solid $background-green;
    &:after {
      background-color: $background-green;
    }
  }
  &-incorrect {
    border: 1px solid $secondary;
    &:after {
      background-color: $secondary;
    }
  }
}
.radio {
  @include displayFlex($display: flex, $direction: row, $justify-content: center, $align-items: center);

  &-container {
    @include displayFlex($display: flex, $justify-content: center, $align-items: center);
    margin: 0 20px 0 20px;
    position: relative;
  }
}

.description {
  @include font($size: 16px, $weight: bold);
  margin: 25px 15px 25px 0;
}
