//  ========================================================================
//  Keyframes
//  ======================================================================== 
@keyframes pulseasdasd {
    from {
        box-shadow: 0 0 0 0 red;
    }

    to {
        box-shadow: 0 0 0 1.2em transparent;
    }
}
