@import '../variables';
@import '../mixins';

.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

.react-player {
  @include position($position: absolute, $top: 0, $left: 0);
}
