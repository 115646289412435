@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center, $wrap: wrap);
  padding: 100px 0 50px;
  color: $white;

  @include media-breakpoint-down(lg) {
    padding: 100px 0 0 0;
  }

  @include media-breakpoint-down(md) {
    padding: 50px 0 0 0;
  }

  h2 {
    @include font($word-spacing: 10px, $transform: uppercase);
    padding-bottom: 80px;

    @include media-breakpoint-down(md) {
      text-align: center;
      padding-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 30px;
    }
  }
}
.wrapper-coaching {
  padding: 100px 0 0 0;
  h2 {
    @include font($word-spacing: 10px, $transform: uppercase);
    padding-bottom: 80px;
    
    @include media-breakpoint-down(sm) {
      padding-bottom: 30px;
    }
  }
}

.services {
  @include displayFlex($display: flex, $wrap: wrap, $justify-content: space-between);

  &-item {
    display: flex;
    width: 46%;
    margin-bottom: 100px;

    &--image {
      margin-right: 30px;

      svg {
        @include size($width: 60px, $height: 57px);
      }
    }
    &-coaching {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      @include displayFlex($align-items: center, $justify-content:center);
      text-align: center;
      }

      &--image {
        @include media-breakpoint-down(sm) {
          margin-right: 0px;
          margin-bottom: 20px;
        }
      }
    }

    &--title {
      @include font($size: 20px, $transform: uppercase, $family: $font-fibon-bold);
      margin-bottom: 18px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    p {
      font-weight: normal;
    }
  }
}